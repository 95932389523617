import { template as template_9e910b97d4a249038fc90b91f780ef8b } from "@ember/template-compiler";
import { service } from '@ember/service';
import AppSidebarNavGroupsEmployeeMyAccountActivity from './nav-groups/employee/my-account-activity';
import AppSidebarNavGroupsEmployeePlanningForCollege from './nav-groups/employee/planning-for-college';
import AppSidebarNavGroupsEmployeePslf from './nav-groups/employee/pslf';
import AppSidebarNavGroupsEmployeeQuestions from './nav-groups/employee/questions';
import AppSidebarNavGroupsEmployeeRepayingStudentDebt from './nav-groups/employee/repaying-student-debt';
import Component from '@glimmer/component';
import type EmployeeService from 'tio-employee/services/employee';
import type FeaturesService from 'tio-common/services/features';
import type IntlService from 'ember-intl/services/intl';
import type PartnerService from 'tio-employee/services/partner';
import type SessionContextService from 'tio-employee/services/session-context';
import type UiSidebarNavComponent from 'tio-common/components/ui/sidebar/nav';
import { and, not } from 'tio-ui/utilities';
export interface AppSidebarFamilyNavComponentSignature {
    Args: {
        nav: typeof UiSidebarNavComponent;
    };
}
class AppSidebarFamilyNavComponent extends Component<AppSidebarFamilyNavComponentSignature> {
    @service
    partner: PartnerService;
    @service
    employee: EmployeeService;
    @service
    sessionContext: SessionContextService;
    @service
    intl: IntlService;
    @service
    features: FeaturesService;
    get isPslfEnabled() {
        return this.partner.isPslfEnabled;
    }
    get isFaqEnabled() {
        return !this.partner.supportModule || this.partner.isFaqEnabled;
    }
    get hasTuitionAssistance() {
        return this.partner.hasNewTuitionAssistance || this.employee.hasLegacyTuitionAssistance;
    }
    get isTaOnly() {
        return this.partner.isTaOnly || this.employee.isTaOnlyEmployee;
    }
    get isTaEnabledSettingExists() {
        return this.partner.companySettings.tuitionAssistance && 'isTaEnabled' in this.partner.companySettings.tuitionAssistance ? true : false;
    }
    get isTaSettingEnabled() {
        return (this.partner.companySettings.tuitionAssistance && !!this.partner.companySettings.tuitionAssistance.isTaEnabled);
    }
    get showTuitionAssistance() {
        if (this.isTaEnabledSettingExists) {
            return this.partner.hasNewTuitionAssistance && this.isTaSettingEnabled;
        } else {
            return this.hasTuitionAssistance;
        }
    }
    get hasContributions() {
        return this.employee.hasContributions;
    }
    get tuitionAssistanceLabel() {
        const settings = this.partner.companySettings;
        const translationKey = settings.employeePortalTab?.toLowerCase();
        return translationKey ? this.intl.t(`sidebar.${translationKey}`) : this.intl.t('sidebar.tuition_assistance');
    }
    get idrEnabled() {
        return this.partner.isIdrEnabled;
    }
    static{
        template_9e910b97d4a249038fc90b91f780ef8b(`
    {{#let @nav as |nav|}}
      {{#if (and this.isTaOnly this.showTuitionAssistance)}}
        {{! @glint-expect-error}}
        <nav.item
          @nestedRoute="tuition-assistance"
          @icon="school"
          @label={{this.tuitionAssistanceLabel}}
        />
      {{else if (and this.isTaOnly (not this.showTuitionAssistance))}}
        {{! @glint-expect-error: no idea}}
        <nav.item @nestedRoute="dashboard" @icon="home" @label="Dashboard" />
      {{else}}
        {{! @glint-expect-error}}
        <nav.item @nestedRoute="dashboard" @icon="home" @label="Dashboard" />

        <AppSidebarNavGroupsEmployeeMyAccountActivity
          @nav={{nav}}
          @hasContributions={{this.hasContributions}}
        />

        {{#if this.isPslfEnabled}}
          <AppSidebarNavGroupsEmployeePslf @nav={{nav}} />
        {{/if}}

        {{#if this.idrEnabled}}
          {{! @glint-expect-error}}
          <nav.item
            @nestedRoute="idr.dashboard"
            @icon="currency_exchange"
            @label="Income-Driven Repayment"
          />
        {{/if}}

        {{#if this.showTuitionAssistance}}
          {{! @glint-expect-error}}
          <nav.item
            @nestedRoute="tuition-assistance"
            @icon="school"
            @label={{this.tuitionAssistanceLabel}}
          />
        {{/if}}

        <AppSidebarNavGroupsEmployeeRepayingStudentDebt @nav={{nav}} @canInviteFamily={{false}} />
        <AppSidebarNavGroupsEmployeePlanningForCollege @nav={{nav}} @canInviteFamily={{false}} />
        <AppSidebarNavGroupsEmployeeQuestions @nav={{nav}} @faqEnabled={{this.isFaqEnabled}} />
      {{/if}}
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default AppSidebarFamilyNavComponent;

import { template as template_a3f05f40f68f4f9caf8527decd58e521 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import LoadingSpinner from 'tio-employee/components/tas/loading-spinner';
export default RouteTemplate(template_a3f05f40f68f4f9caf8527decd58e521(`
    <div class="flex h-screen w-screen items-center justify-center">
      <LoadingSpinner />
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));

import { template as template_65c4e3efe1064d26ac85f1259a196e45 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import AppContentCard from 'tio-common/components/app/content-card';
import Component from '@glimmer/component';
import TioClickableText from 'tio-common/components/tio/clickable-text';
import { t } from 'ember-intl';
import type AccountModel from 'tio-common/models/account';
import type { IntlService } from 'ember-intl';
import type RouterService from 'tio-employee/services/router';
import type SessionContextService from 'tio-employee/services/session-context';
import type Store from '@ember-data/store';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import { Section } from 'tio-ui/components/layout';
interface SyfAccoutLinkedSignature {
    Args: {
        account: AccountModel;
        onConfirmation: () => void;
    };
}
class SyfAccountLinkedComponent extends Component<SyfAccoutLinkedSignature> {
    @service
    intl: IntlService;
    @service
    router: RouterService;
    @service
    sessionContext: SessionContextService;
    @service
    store: typeof Store;
    get mainTitle() {
        return `${this.intl.t('account_linking.service_provider')}: ${this.args.account?.institutionName}`;
    }
    @action
    onAddStudentLoan() {
        this.router.transitionTo('authenticated.observability.upload', {
            queryParams: {
                source: 'syf'
            }
        });
    }
    static{
        template_65c4e3efe1064d26ac85f1259a196e45(`
    <h2 class="text-midnight text-large font-semibold mb-2">{{t "account_linking.default"}}</h2>
    <h4 class="font-medium mb-6">{{t "account_linking.heading"}}</h4>
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 mb-4 gap-8">
      <AppContentCard @title={{this.mainTitle}} @titleClass="mb-6" class="col-span-1 mb-5">
        {{#if @account.activeLoans}}
          {{#each @account.activeLoans as |loan|}}
            <Section class="!bg-white mb-2">
              <:body>
                <dl class="grid grid-cols-2 gap-4">
                  <dt class="mr-4">{{t "account_linking.loan_name"}}</dt>
                  <dd class="font-semibold">{{loan.name}}</dd>
                  <dt class="mr-4">{{t "account_linking.balance"}}</dt>
                  <dd class="font-semibold">
                    {{loan.currentBalanceFormatted}}
                  </dd>
                </dl>
              </:body>
            </Section>
          {{/each}}
        {{else}}
          <div>
            {{t "account_linking.no_active_loans"}}
          </div>
        {{/if}}
      </AppContentCard>
    </div>
    <TioClickableText
      @icon="add"
      @iconLeft={{true}}
      @outlined={{true}}
      @onClick={{this.onAddStudentLoan}}
      @textClass="font-semibold uppercase text-sm text-tio-gray-700"
    >
      {{t "account_linking.add_a_student_loan_account"}}
    </TioClickableText>
    <div class="flex justify-around mt-8">
      <Button {{on "click" @onConfirmation}} @intent="primary" @appearance="outlined" class="px-8">
        {{t "syf.enrollment.continue_to_enrollment"}}
      </Button>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default SyfAccountLinkedComponent;

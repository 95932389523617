import { template as template_e7bde2dc653a4987814b2bba6d3442e8 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface QuestionsLoanTypesCardSignature {
    Args: {
        imageAlt?: string;
        label: string;
        src: string;
    };
    Element: HTMLDivElement;
}
const QuestionsLoanTypesCardComponent: TOC<QuestionsLoanTypesCardSignature> = template_e7bde2dc653a4987814b2bba6d3442e8(`
  <div
    class="text-center md:col-4 col-12 p-5 block max-w-2/6 whitespace-normal relative border-b"
    ...attributes
  >
    <img class="mx-auto mb-4 h-12 w-12 min-w-12" alt={{@imageAlt}} src={{@src}} />
    <span>{{@label}}</span>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default QuestionsLoanTypesCardComponent;

import { template as template_59d9eedc85f84420a6185f6e247f3c9a } from "@ember/template-compiler";
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import AppAlertNoLinkedLoans from 'tio-employee/components/app/alert/no-linked-loans';
import LinkedAccountsCard from 'tio-employee/components/accounts/linked-accounts-card';
import DashResults from 'tio-employee/components/recommendations/dash-results';
import MonthlyPaymentForgivenessCard from 'tio-employee/components/recommendations/monthly-payment-forgiveness-card';
import type { SummaryByLoanType } from 'tio-employee/types/loan-summary';
import type UserModel from 'tio-common/models/user';
import { HStack, Section } from 'tio-ui/components/layout';
interface S {
    Args: {
        model: UserModel;
    };
}
class StrategyFinderDashboardIndexRoute extends Component<S> {
    get hasGraduatedPlan() {
        const summary = this.args.model.loanSummary;
        return summary.summaryByLoanType.some((typeSummary: SummaryByLoanType)=>typeSummary.repaymentPlan === 'graduated');
    }
    static{
        template_59d9eedc85f84420a6185f6e247f3c9a(`
    {{#if @model.person.needsToAnswerWhyNoLinkedLoans}}
      <AppAlertNoLinkedLoans @person={{@model.person}} />
    {{/if}}
    <HStack>
      <LinkedAccountsCard
        @accounts={{@model.accounts}}
        @loanSummary={{@model.loanSummary}}
        @person={{@model.person}}
        @programName={{t "common.strategy_finder.default"}}
      />

      {{#if this.hasGraduatedPlan}}
        <Section>
          <:body>
            {{t "pslf_dashboard.actions.dialog.graduated_plan"}}
          </:body>
        </Section>
      {{else}}
        <MonthlyPaymentForgivenessCard
          @accounts={{@model.accounts}}
          @loanSummary={{@model.loanSummary}}
          @person={{@model.person}}
          @programType="sf"
          @user={{@model.user}}
          class="lg:col-span-4 col-span-6"
          @isCanceling={{false}}
        />
      {{/if}}
    </HStack>
    {{#if @model.mostRecentRecommendation}}
      <DashResults
        @accounts={{@model.accounts}}
        @allRecommendations={{@model.allRecommendations}}
        @loanSummary={{@model.loanSummary}}
        @person={{@model.person}}
        @programType="sf"
        @recommendation={{@model.mostRecentRecommendation}}
        class="lg:col-span-4 col-span-6"
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(StrategyFinderDashboardIndexRoute);

import { template as template_39256eb5dfbe4b7b94c712a135400a0d } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import Tile from '../tile';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
const DashboardWidgetsScholarshipComponent: TOC = template_39256eb5dfbe4b7b94c712a135400a0d(`
  <LinkTo @route="authenticated.planning-for-college.scholarship">
    <Tile @headerText={{t "dashboard_tiles.scholarship_info"}}>
      <:description>
        <span>{{t "dashboard_tiles.helpful_info"}}</span>
      </:description>
      <:image>
        {{svgJar "startup" width="80%" height="100%" role="img" desc=(t "svg.startup")}}
      </:image>
    </Tile>
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashboardWidgetsScholarshipComponent;

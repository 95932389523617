import { template as template_cb6e5f107f12416ea1d62326166e8aa8 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const FiveTwoNineFaqsComponent: TOC = template_cb6e5f107f12416ea1d62326166e8aa8(`
  <div class="py-4">
    <div class="py-2 text-gray-800 text-lg font-semibold">
      {{t "planning_for_college.five_two_nine.faq_0_q"}}
    </div>
    <div class="py-2 text-gray-600 text-base font-normal">
      {{t "planning_for_college.five_two_nine.faq_0_a"}}
    </div>
  </div>
  <div class="py-4">
    <div class="py-2 text-gray-800 text-lg font-semibold">
      {{t "planning_for_college.five_two_nine.faq_1_q"}}
    </div>
    <div class="py-2 text-gray-600 text-base font-normal">
      {{t "planning_for_college.five_two_nine.faq_1_a"}}
    </div>
  </div>
  <div class="py-4">
    <div class="py-2 text-gray-800 text-lg font-semibold">
      {{t "planning_for_college.five_two_nine.faq_2_q"}}
    </div>
    <div class="py-2 text-gray-600 text-base font-normal">
      {{t "planning_for_college.five_two_nine.faq_2_a"}}
    </div>
  </div>
  <div class="py-4">
    <div class="py-2 text-gray-800 text-lg font-semibold">
      {{t "planning_for_college.five_two_nine.faq_3_q"}}
    </div>
    <div class="py-2 text-gray-600 text-base font-normal">
      {{t "planning_for_college.five_two_nine.faq_3_a"}}
    </div>
  </div>
  <div class="py-4">
    <div class="py-2 text-gray-800 text-lg font-semibold">
      {{t "planning_for_college.five_two_nine.faq_4_q"}}
    </div>
    <div class="py-2 text-gray-600 text-base font-normal">
      {{t "planning_for_college.five_two_nine.faq_4_a"}}
    </div>
  </div>
  <div class="py-4">
    <div class="py-2 text-gray-800 text-lg font-semibold">
      {{t "planning_for_college.five_two_nine.faq_5_q"}}
    </div>
    <div class="py-2 text-gray-600 text-base font-normal">
      {{t "planning_for_college.five_two_nine.faq_5_a"}}
    </div>
  </div>
  <div class="flex justify-center items-center py-10 px-2">
    <img
      class="w-full max-w-96"
      alt={{t "planning_for_college.five_two_nine.saving_for_college_alt"}}
      src="/assets/images/planning-for-college/online-courses.png"
    />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FiveTwoNineFaqsComponent;

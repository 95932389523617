import { template as template_7984f2f19315463eb50fdae9222950b8 } from "@ember/template-compiler";
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import { Header, HStack, Section, VStack } from 'tio-ui/components/layout';
import Check from 'ember-static-heroicons/components/outline-24/check';
const SyfInsightsPslfRouteComponent = template_7984f2f19315463eb50fdae9222950b8(`
  <TioPageBreadcrumbs class="mb-4" as |b|>
    <b.crumb @route="authenticated.syf.dashboard" @label="Dashboard" />
    <b.crumb @route="authenticated.syf.insights.pslf" @label="PSLF" />
  </TioPageBreadcrumbs>
  <Header>{{t "syf.insights.pslf.heading"}}</Header>
  <Section>
    <:body>
      <HStack>
        <VStack class="w-full md:w-1/2">
          <h2 class="text-lg font-semibold">{{t "syf.insights.pslf.explore.heading"}}</h2>
          <p>{{t "syf.insights.pslf.explore.one" htmlSafe=true}}</p>
          <p>{{t "syf.insights.pslf.explore.two"}}</p>
        </VStack>
        <VStack class="w-full md:w-1/2">
          <h2 class="text-lg font-semibold">{{t "syf.insights.pslf.best_options.heading"}}</h2>
          <div class="flex gap-2 items-start">
            <Check class="min-w-6 text-green-700" />
            <p>{{t "syf.insights.pslf.best_options.one"}}</p>
          </div>
          <div class="flex gap-2 items-start">
            <Check class="min-w-6 text-green-700" />
            <p>{{t "syf.insights.pslf.best_options.two"}}</p>
          </div>
          <div class="flex gap-2 items-start">
            <Check class="min-w-6 text-green-700" />
            <p>{{t "syf.insights.pslf.best_options.three"}}</p>
          </div>
          <div class="flex gap-2 items-start">
            <Check class="min-w-6 text-green-700" />
            <p>{{t "syf.insights.pslf.best_options.four"}}</p>
          </div>
          <hr />
          <div class="flex gap-2 items-start">
            <p class="text-red-600 font-extrabold">&excl;</p>
            <p class="font-bold">{{t "syf.insights.pslf.best_options.action"}}</p>
          </div>
          <div>{{t "syf.insights.pslf.best_options.label"}}</div>
          <Button
            @intent="primary"
            @appearance="outlined"
            {{on "click" (transitionTo "authenticated.idr")}}
          >
            {{t "syf.insights.pslf.best_options.button"}}
          </Button>
        </VStack>
      </HStack>
    </:body>
  </Section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(SyfInsightsPslfRouteComponent);

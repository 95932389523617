import { template as template_f9b756a019d54b03aab545ac0622ba06 } from "@ember/template-compiler";
import { capitalize } from 'tio-common/utils/format';
import { formatAsDollars } from 'tio-common/utils/format';
import { hash } from '@ember/helper';
import { isEmpty, not } from 'tio-ui/utilities';
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import Table from 'tio-common/components/table/index';
import type AccountTransactionModel from 'tio-common/models/account-transaction';
import { Section } from 'tio-ui/components/layout';
type LoanPaymentsProps = {
    Args: {
        eligiblePayments: AccountTransactionModel[];
        unverifiedPayments: AccountTransactionModel[];
        ineligiblePayments: AccountTransactionModel[];
        getPaymentCertificationState: (accountTransaction: AccountTransactionModel) => string;
    };
    Blocks: {
        default: [];
    };
};
// NB: initially intended this component to implement an overly composable pattern,
// but that turned out to be more mental overhead than implementing some of the
// necessary conditional logic internally so have begun transitioning to a more
// encapsulated implementation, starting with the ineligible payments table in
// response to a header/cell mismatch bug
// - james 20240313
class LoanPayments extends Component<LoanPaymentsProps> {
    // TODO: bad, bad, not good, regex or exhaustive switch here is strictly
    // better than messing with string delimiters - james 20241223
    formatPaymentVerificationState(verificationState: string): string {
        return (verificationState.split('.').pop() || '').split('_').map(capitalize).join(' ');
    }
    static{
        template_f9b756a019d54b03aab545ac0622ba06(`
    <Section>
      <:header>{{t "syf.dashboard.payments.header"}}</:header>
      <:body>
        <div class="mb-4">
          {{t "syf.dashboard.payments.add_link_prefix"}}
          <LinkTo
            @route="authenticated.observability.upload"
            @query={{hash source="syf_payments"}}
            class="mb-4 text-ocean-600"
          >
            {{t "syf.dashboard.payments.add_link_text"}}
          </LinkTo>
        </div>
        <h2 class="font-semibold text-lg mb-2">{{t "syf.dashboard.payments.qualified.header"}}</h2>
        <Table class="mb-4" @isLoading={{false}}>
          <:thead as |options|>
            <options.tr>
              <options.th>{{t "syf.dashboard.payments.qualified.table.payment_header"}}</options.th>
              <options.th>{{t
                  "syf.dashboard.payments.qualified.table.servicer_header"
                }}</options.th>
              <options.th>{{t
                  "syf.dashboard.payments.qualified.table.payment_date_header"
                }}</options.th>
              <options.th>{{t "syf.dashboard.payments.qualified.table.status_header"}}</options.th>
            </options.tr>
          </:thead>

          <:tbody as |options|>
            {{#if (not (isEmpty @eligiblePayments))}}
              {{#each @eligiblePayments as |eligiblePayment|}}
                <options.tr>
                  <options.td>{{formatAsDollars eligiblePayment.amount}}</options.td>
                  <options.td>{{eligiblePayment.financialInstitution.name}}</options.td>
                  <options.td>{{safeFormatDate
                      eligiblePayment.transactionDate
                      month="2-digit"
                      day="2-digit"
                      year="numeric"
                    }}</options.td>
                  <options.td>
                    {{t (@getPaymentCertificationState eligiblePayment)}}
                  </options.td>
                </options.tr>
              {{/each}}
            {{else}}
              <div class="mt-2"><i>{{t "syf.dashboard.payments.qualified.empty"}}</i></div>
            {{/if}}
          </:tbody>

        </Table>
        <h2 class="font-semibold text-lg mb-2">{{t "syf.dashboard.payments.unverified.header"}}</h2>
        <Table class="mb-4" @isLoading={{false}}>
          <:thead as |options|>
            <options.tr>
              <options.th>{{t
                  "syf.dashboard.payments.unverified.table.payment_header"
                }}</options.th>
              <options.th>{{t
                  "syf.dashboard.payments.qualified.table.payment_date_header"
                }}</options.th>
              <options.th>{{t "syf.dashboard.payments.unverified.table.status_header"}}</options.th>
              <options.th>{{t
                  "syf.dashboard.payments.unverified.table.creation_date_header"
                }}</options.th>
              <options.th>{{t "syf.dashboard.payments.unverified.table.reason_header"}}</options.th>
            </options.tr>
          </:thead>
          <:tbody as |options|>
            {{#if (not (isEmpty @unverifiedPayments))}}
              {{#each @unverifiedPayments as |payment|}}
                <options.tr>
                  <options.td>{{formatAsDollars payment.amount}}</options.td>
                  <options.td>{{safeFormatDate
                      payment.transactionDate
                      month="2-digit"
                      day="2-digit"
                      year="numeric"
                    }}</options.td>
                  <options.td>{{this.formatPaymentVerificationState
                      payment.verificationState
                    }}</options.td>
                  <options.td>{{safeFormatDate
                      payment.createdAt
                      month="2-digit"
                      day="2-digit"
                      year="numeric"
                    }}</options.td>
                  <options.td>{{payment.rejectionReason}}</options.td>
                </options.tr>
              {{/each}}
            {{else}}
              <div class="mt-2"><i>{{t "syf.dashboard.payments.unverified.empty"}}</i></div>
            {{/if}}
          </:tbody>
        </Table>
        <h2 class="font-semibold text-lg mb-2">
          {{t "syf.dashboard.payments.unqualified.header"}}
        </h2>
        <Table class="mb-4" @isLoading={{false}}>
          <:thead as |options|>
            <options.tr>
              <options.th>{{t
                  "syf.dashboard.payments.unqualified.table.payment_header"
                }}</options.th>
              <options.th>{{t
                  "syf.dashboard.payments.unqualified.table.servicer_header"
                }}</options.th>
              <options.th>{{t
                  "syf.dashboard.payments.unqualified.table.payment_date_header"
                }}</options.th>
            </options.tr>
          </:thead>
          <:tbody as |options|>
            {{#if (not (isEmpty @ineligiblePayments))}}
              {{#each @ineligiblePayments as |payment|}}
                <options.tr>
                  <options.td>{{formatAsDollars payment.amount}}</options.td>
                  <options.td>{{payment.financialInstitution?.name}}</options.td>
                  <options.td>{{safeFormatDate
                      payment.transactionDate
                      month="2-digit"
                      day="2-digit"
                      year="numeric"
                    }}</options.td>
                </options.tr>
              {{/each}}
            {{else}}
              <div class="mt-2"><i>{{t "syf.dashboard.payments.unqualified.empty"}}</i></div>
            {{/if}}
          </:tbody>
        </Table>
      </:body>
    </Section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default LoanPayments;

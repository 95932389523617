import { template as template_273a076dc55849acbc1da401734217ae } from "@ember/template-compiler";
import { action } from '@ember/object';
import { fn } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import { htmlSafe } from '@ember/template';
import { on } from '@ember/modifier';
import { tracked } from '@glimmer/tracking';
import { TrackedObject } from 'tracked-built-ins';
import AppContentCard from 'tio-common/components/app/content-card';
import Component from '@glimmer/component';
import SyfFaqs from './faqs';
import TioStepOverview from 'tio-common/components/tio/step-overview';
import type MatchPlanModel from 'tio-common/models/match-plan';
import { Button } from 'tio-ui/components/buttons';
export interface SyfEnrollmentSignature {
    Args: {
        onBeginEnrollment: () => void;
        beginEnrollmentLoading: boolean;
        matchPlan: MatchPlanModel;
    };
}
class SyfEnrollmentComponent extends Component<SyfEnrollmentSignature> {
    @tracked
    options: Record<string, boolean> = new TrackedObject({
        option1: false,
        option2: false,
        option3: false,
        option4: false
    });
    option1Text = `<div><span class="text-fuscia-300">I do not contribute</span> to my employer's 401(k)</div>`;
    option2Text = `<div>I make <span class="text-fuscia-300">contributions</span> to my 401(k) and do not receive the full match from my employer</div>`;
    option3Text = `<div>I make contributions to my 401(k), but <span class="text-fuscia-300">I am unsure whether I receive the full match from my employer</span></div>`;
    option4Text = `<div><span class="text-fuscia-300">I contribute to my 401(k)</span> and receive the full possible match from my employer</div>`;
    get secureYourFutureHeader() {
        return `What is <span class="text-violet-800">Secure Your Future</span>?`;
    }
    get secureOptionCards() {
        return [
            {
                text: this.option1Text,
                dataKey: 'option1'
            },
            {
                text: this.option2Text,
                dataKey: 'option2'
            },
            {
                text: this.option3Text,
                dataKey: 'option3'
            },
            {
                text: this.option4Text,
                dataKey: 'option4'
            }
        ];
    }
    get enrollmentSteps() {
        return [
            'Connect your loans',
            'Confirm your loan payments',
            'Receive matching contributions'
        ];
    }
    @action
    showOptionNumber(option: string) {
        for(const key in this.options){
            if (key !== option) {
                this.options[key] = false;
            }
        }
        this.options[option] = true;
        console.log(option, this.options);
        document.getElementById('button_container')?.scrollIntoView({
            behavior: 'smooth'
        });
    }
    static{
        template_273a076dc55849acbc1da401734217ae(`
    {{! template-lint-disable no-bare-strings }}
    <h1 class="text-4xl md:text-5xl font-semibold text-center py-6">Secure your future!</h1>
    <h2 class="text-2xl text-center w-9/12 mx-auto font-light">You can now get an employer match
      into a 401(k) plan for qualifying payments towards your student loans.</h2>

    <AppContentCard
      class="mx-4 my-6 p-6 font-medium"
      @title={{this.secureYourFutureHeader}}
      @titleClass="text-xl"
      @bgColorClass="bg-violet-50"
    >
      <p class="my-4">
        The US government's SECURE 2.0 legislation introduces several changes which benefit
        employees in their path to retirement starting
        <span class="font-semibold">01/01/2024.</span>
      </p>
      <p>
        <span class="text-violet-800 font-semibold">Secure Your Future</span>
        is a benefit for employees who are
        <span class="font-semibold">making qualifying student loan payments</span>
        to get an
        <span class="font-semibold">employer match into a 401(k) plan.</span>
      </p>
    </AppContentCard>

    <AppContentCard
      class="mx-4 my-6 p-6 font-medium"
      @title="Ready To Enroll?"
      @titleClass="text-xl"
      @bgColorClass="bg-violet-50"
    >
      Enrolling in this benefit is a simple three step process. Tuition.io as your employer’s
      service provider has an account ready to go once you have completed the enrollment process.

      <div class="flex justify-center my-6">
        <TioStepOverview @steps={{this.enrollmentSteps}} />
      </div>

      <Button
        @intent="primary"
        {{on "click" @onBeginEnrollment}}
        @isRunning={{@beginEnrollmentLoading}}
        class="w-60 mx-auto my-4"
      >
        START
      </Button>
    </AppContentCard>

    <section class="mx-10">
      <h3 class="text-xl font-semibold">Want to learn more</h3>
      <p class="my-3 leading-relaxed">
        This benefit applies to employees that are making qualified student loan payments and
        enrolled in the Secure Your Future benefit on Tuition.io. If you do not have student loans
        or have determined that the Secure Your Future benefit is not right for you at this time,
        explore our other benefits here
      </p>
      <p>If you are making student loan payents,
        <span class="font-semibold">choose the option that best describes you.</span></p>

      <div id="button_container" class="flex flex-wrap justify-center gap-4 font-semibold my-8">
        {{#each this.secureOptionCards as |card|}}
          <button
            type="button"
            class="border border-gray-100 rounded-md shadow overflow-hidden w-64 h-28 p-2 flex items-center hover:bg-violet-100"
            {{on "click" (fn this.showOptionNumber card.dataKey)}}
          >
            <div class="text-center">
              {{htmlSafe card.text}}
            </div>
          </button>
        {{/each}}
      </div>
      {{#if this.options.option1}}
        <article>
          <h2 class="font-semibold my-6">{{htmlSafe this.option1Text}}</h2>
          <AppContentCard
            class="mx-4 my-6 p-6"
            @title="How can this benefit help you?"
            @titleClass="text-xl"
            @bgColorClass="bg-violet-50"
          >
            <h1 class="font-medium">Since you are making payments on your loans but have not yet
              contributed to your employer's 401(k), here is what we recommend you consider:</h1>
            <ol class="list-decimal p-4 leading-loose">
              <li>Enrolling is easy and it will allow you to begin saving for retirement without
                needing to make a 401(k) deferral. The contribution to your 401(k) will come from
                your employer's match of your student loan payment amounts.</li>
              <li>Once enrolled, you simply need to keep making your student loan payments as you
                are today. The Secure Your Future match is deposited into your 401(k) automatically
                by your employer after you have provided the required certification.</li>
            </ol>
          </AppContentCard>
        </article>
      {{/if}}

      {{#if this.options.option2}}
        <article>
          <h2 class="font-semibold my-6 p-6">{{htmlSafe this.option2Text}}</h2>
          <AppContentCard
            class="mx-4 my-6"
            @title="How can this benefit help you?"
            @titleClass="text-xl"
            @bgColorClass="bg-violet-50"
          >
            <h1 class="font-medium">Since you are making payments on your loans and make
              contributions to your employer’s 401(k), you can obtain a greater match through
              participating in this benefit:</h1>
            <ol class="list-decimal p-4 leading-loose">
              <li>An employer will match your student loan payment in addition to any match you are
                receiving on your 401(k) deferral.</li>
              <li>Once enrolled, you simply need to keep making your student loan payments as you
                are today. The Secure Your Future match is deposited into your 401(k) automatically
                by your employer after you have provided the required certification.</li>
            </ol>
          </AppContentCard>
        </article>
      {{/if}}

      {{#if this.options.option3}}
        <article>
          {{! https://app.zeplin.io/project/64f750d695cdca3fe7c3db49/screen/652f0c1515eae84e152417ff}}
          <h2 class="font-semibold my-6 p-6">{{htmlSafe this.option3Text}}</h2>
          <AppContentCard
            class="mx-4 my-6"
            @title="How can this benefit help you?"
            @titleClass="text-xl"
            @bgColorClass="bg-violet-50"
          >
            <h1 class="font-medium">Since you are making payments on your loans and make
              contributions to your employer’s 401(k), you can potentially obtain a greater match
              through participating in this benefit:</h1>
            <ol class="list-decimal p-4 leading-loose">
              <li>Your employer will match your student loan payment in addition to any match you
                are receiving on your 401(k) deferral.</li>
              <li>This Secure Your Future enrollment will help determine how much of your salary
                needs to be deferred in order to receive your employer’s full match. Once you have
                completed enrollment, you can check your 401(k) deferral election to determine if
                you will be receiving the full match.</li>
              <li>Once enrolled, you simply need to keep making your student loan payments as you
                are today. The Secure Your Future match is deposited into your 401(k) automatically
                by your employer after you have provided the required certification.</li>
            </ol>
          </AppContentCard>
        </article>
      {{/if}}

      {{#if this.options.option4}}
        <article>
          <h2 class="font-semibold my-6">{{htmlSafe this.option4Text}}</h2>
          <AppContentCard
            class="mx-4 my-6 p-6"
            @title="How can this benefit help you?"
            @titleClass="text-xl"
            @bgColorClass="bg-violet-50"
          >
            <h1 class="font-medium">Since you are already receiving the full possible match from
              your employer towards your 401(k), here is how this might still be useful, depending
              on your individual circumstances:</h1>
            <ol class="list-decimal p-4 leading-loose">
              <li>If you are deferring your salary to maximize your employer's match, you could
                change that going forward. If you choose to reduce your deferral election, your
                student loan payments would allow you to still potentially receive your employer's
                maximum match. You may benefit from reducing your retirement deferral if you need
                additional cash flow. However, this will reduce your total retirement savings since
                you will be contributing less of your own money to retirement.</li>
              <li>Once enrolled, you simply need to keep making your student loan payments as you
                are today. The Secure Your Future match is deposited into your 401(k) automatically
                by your employer after you have provided the required certification.</li>
              <li>Reducing your deferral elections to account for student loan payments eligible for
                the Secure Your Future program is a complicated decision for participants and will
                depend upon each participant's individual circumstances. Your employer encourages
                you to discuss this decision with a Tuition.io representative. Click
                <LinkTo
                  @route="authenticated.repaying-student-debt.askjeni.schedule"
                  class="font-semibold text-ocean-600 hover:underline"
                >here</LinkTo>
                to contact a coach.</li>
            </ol>
          </AppContentCard>
        </article>
      {{/if}}
    </section>
    <SyfFaqs @faqs={{@matchPlan.faqs}} />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default SyfEnrollmentComponent;

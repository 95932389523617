import { template as template_5679d32c9d064d2589a4d38279674e48 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface S {
    Args: {
        nav: unknown;
        faqEnabled: boolean;
    };
}
const AppSidebarNavGroupsEmployeeQuestionsComponent: TOC<S> = template_5679d32c9d064d2589a4d38279674e48(`
  {{#let @nav as |nav|}}
    {{! @glint-expect-error: no idea}}
    <nav.group
      @icon="import_contacts"
      @label="Questions You Have"
      @nestedRoute="questions-you-have"
      as |group|
    >
      {{#if @faqEnabled}}
        <group.item
          @href="https://tio.zendesk.com/hc/en-us/categories/360000194112-FAQ"
          @label="Common Questions"
          @secondaryIcon="launch"
        />
      {{/if}}
      <group.item @nestedRoute="paying-for-college" @label="Types of Student Loans" />
      <group.item @nestedRoute="repayment-plans" @label="Federal Loan Repayment Plans" />
      <group.item @nestedRoute="repayment-options" @label="Consolidation & Refinancing" />
      <group.item @nestedRoute="loan-forgiveness" @label="Loan Forgiveness" />
      <group.item @nestedRoute="glossary" @label="Student Loan Glossary" />
      {{! @glint-expect-error: no idea}}
    </nav.group>
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppSidebarNavGroupsEmployeeQuestionsComponent;

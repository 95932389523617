import { template as template_2c323f1febd546d2a83267ce7f7f7c41 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { VStack } from 'tio-ui/components/layout';
interface SlrRouteSignature {
    Blocks: {
        default: [];
    };
}
export default RouteTemplate<SlrRouteSignature>(template_2c323f1febd546d2a83267ce7f7f7c41(`
    <VStack>
      {{outlet}}
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));

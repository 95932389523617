import { template as template_c7c25cbe6ab64f9d825617488a4e613b } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import { Header, VStack } from 'tio-ui/components/layout';
export default RouteTemplate(template_c7c25cbe6ab64f9d825617488a4e613b(`
    <VStack>
      <Header>{{t "idr.default"}}</Header>
      {{outlet}}
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));

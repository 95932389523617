import { template as template_546bf19d8e9545b598a667d09acf7090 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import safeT from 'tio-common/helpers/safe-t';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import DashboardGridLayout from 'tio-employee/components/dashboard/grid-layout';
import DashboardTile from 'tio-employee/components/dashboard/tile';
import { LinkTo } from '@ember/routing';
interface S {
    Args: {
        model: {
            id: string;
            titleKey: string;
        }[];
    };
}
export default RouteTemplate<S>(template_546bf19d8e9545b598a667d09acf7090(`
    {{#if @model.length}}
      <DashboardGridLayout>
        {{#each @model as |report|}}
          <LinkTo @route="authenticated.admin.reports.show" @model={{report.id}}>
            <DashboardTile @headerText={{safeT report.titleKey}}>
              <:image>
                {{svgJar "growth" width="100%" role="img" desc=(t "svg.growth")}}
              </:image>
            </DashboardTile>
          </LinkTo>
        {{/each}}
      </DashboardGridLayout>
    {{else}}
      <div class="text-center p-8 text-gray-600 w-full">{{t "reports.no_reports"}}</div>
    {{/if}}
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));

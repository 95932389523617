import { template as template_66cde1ec451445e9ad64434802999959 } from "@ember/template-compiler";
import { concat } from '@ember/helper';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import lowercase from 'ember-cli-string-helpers/helpers/lowercase';
import type RoleViewModel from 'tio-common/models/role-view';
import type SessionContextService from 'tio-employee/services/session-context';
import UiAvatar from 'tio-common/components/ui/avatar';
export interface AuthLoginRoleSelectCardSignature {
    Args: {
        role: RoleViewModel;
        onClick: () => void;
    };
    Element: HTMLDivElement;
}
const roleColors = {
    ACCOUNT_OWNER: '#ff793f',
    EMPLOYEE_ADMIN: '#ff793f',
    PARTICIPANT: '#06c46b',
    TIO_ACCOUNT_MANAGER: '#5D2193'
};
export default class AuthLoginRoleSelectCardComponent extends Component<AuthLoginRoleSelectCardSignature> {
    @service
    sessionContext: SessionContextService;
    get initials() {
        const { firstName, lastName } = this.sessionContext.user.person;
        return `${firstName[0]}${lastName[0]}`;
    }
    get color() {
        // @ts-expect-error: need better types for role
        return roleColors[this.args.role.relationshipType];
    }
    static{
        template_66cde1ec451445e9ad64434802999959(`
    <div
      {{! template-lint-disable no-invalid-interactive }}
      class="w-[200px] shadow-xl hover:shadow-xl border hover:border-gray-400 rounded-sm cursor-pointer flex flex-col items-center"
      data-relationship={{@role.relationshipType}}
      {{on "click" @onClick}}
      ...attributes
    >
      <h5 class="p-5 uppercase font-semibold text-neutral-800 text-center">
        {{t (concat "user-roles." (lowercase @role.relationshipType))}}
      </h5>

      <hr class="self-stretch" />

      <UiAvatar class="m-5" @initials={{this.initials}} @color={{this.color}} @size={{124}} />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}

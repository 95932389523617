import { template as template_2d362eb7f73a48ba874d29533c087ecf } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
interface SyfPaymentsRouteSignature {
    Blocks: {
        default: [];
    };
}
export default RouteTemplate<SyfPaymentsRouteSignature>(template_2d362eb7f73a48ba874d29533c087ecf(`{{outlet}}`, {
    eval () {
        return eval(arguments[0]);
    }
}));

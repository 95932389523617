import { template as template_b9cc2784a92549c0bcbf9266122a9e28 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import TioLoadingSpinner from 'tio-common/components/tio/loading-spinner';
export default RouteTemplate(template_b9cc2784a92549c0bcbf9266122a9e28(`
    <div class="flex flex-col h-screen container mx-auto">
      <h2 class="mt-6 text-center text-lg">{{t "login.in_progress"}}</h2>

      <div class="grow flex items-center">
        <TioLoadingSpinner />
      </div>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));

import { template as template_851334c632964d069e9892f9aac2592b } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { VStack } from 'tio-ui/components/layout';
export default RouteTemplate(template_851334c632964d069e9892f9aac2592b(`
    <VStack>{{outlet}}</VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));

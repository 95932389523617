import { template as template_286ab30c57454f7b9c7575d0afa48a20 } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import type { LimitsStatusDataSignature } from 'tio-common/services/tuition-assistance';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import formatCurrencySuperscriptCents from 'tio-common/helpers/format-currency-superscript-cents';
export interface TuitionAssistanceMyPaymentActivitySignature {
    Args: {
        limitsStatus: LimitsStatusDataSignature;
    };
    Element: HTMLDivElement;
}
export default class TuitionAssistanceMyPaymentActivity extends Component<TuitionAssistanceMyPaymentActivitySignature> {
    @service
    store: typeof Store;
    @service
    intl: IntlService;
    get startDate() {
        return this.args.limitsStatus.annual_start_date;
    }
    get endDate() {
        return this.args.limitsStatus.annual_end_date;
    }
    get lifetimeUsage() {
        return this.args.limitsStatus.details.company.usage || 0;
    }
    get annualUsage(): number {
        return this.args.limitsStatus.details.company.annual_usage || 0;
    }
    static{
        template_286ab30c57454f7b9c7575d0afa48a20(`
    <div>
      <div class="flex flex-col my-5 gap-y-4">
        <div class="flex items-start gap-4">
          <div class="flex flex-col flex-1 text-left text-lg text-tio-gray-700 font-medium">
            {{t "tuition_assistance.dashboard.payment_activity.lifetime_usage"}}
            <div class="text-blue-800 font-semibold text-2xl tracking-wide">
              {{formatCurrencySuperscriptCents this.lifetimeUsage}}
            </div>
          </div>
          <div class="flex flex-col flex-1 text-left text-lg text-tio-gray-700">
            <div class="flex flex-wrap items-center gap-x-2">
              <div class="font-medium">
                {{t "tuition_assistance.dashboard.payment_activity.annual_usage"}}
              </div>
              <div
                class="rounded bg-tio-gray-100 self-center text-left text-xs px-2 py-1 text-balance"
              >
                {{safeFormatDate this.startDate month="short" day="2-digit" year="numeric"}}
                -
                {{safeFormatDate this.endDate month="short" day="2-digit" year="numeric"}}
              </div>
            </div>
            <div class="text-violet-400 font-semibold text-2xl tracking-wide">
              {{formatCurrencySuperscriptCents this.annualUsage}}
            </div>
          </div>
        </div>
      </div>

      <div>
        <LinkTo @route="authenticated.tuition-assistance.my-payment-details" class="flex tio-copy">
          {{t "tuition_assistance.dashboard.payment_activity.payment_details"}}
          <span class="no-underline">
            <MaterialIcon @icon="play_arrow" />
          </span>
        </LinkTo>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}

import { template as template_1a7d7926b4044035bb1fe130bba8f594 } from "@ember/template-compiler";
import { array } from '@ember/helper';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import ApproverComments from 'tio-common/components/tuition-assistance/approver-comments';
import Component from '@glimmer/component';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import TuitionAssistanceApplicationDetailsApprovalQueueStatus from 'tio-common/components/tuition-assistance/application-details/approval-queue-status';
import TuitionAssistanceApplicationDetailsApproverOverview from 'tio-common/components/tuition-assistance/application-details/approver-overview';
import TuitionAssistanceApplicationDetailsEmployeeInformation from 'tio-common/components/tuition-assistance/application-details/employee-information';
import TuitionAssistanceApplicationDetailsProgramStatusApproval from 'tio-common/components/tuition-assistance/application-details/program-status-approval';
import TuitionAssistanceProgramDetailsAppDetailsAppHistory from 'tio-common/components/tuition-assistance/program-details/app-details/app-history';
import TuitionAssistanceProgramDetailsApplicationInformation from 'tio-common/components/tuition-assistance/program-details/application-information';
import TuitionAssistanceProgramDetailsProgramInfoCard from 'tio-common/components/tuition-assistance/program-details/program-info-card';
import type SessionContextService from 'tio-employee/services/session-context';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import { HStack, Section, VStack } from 'tio-ui/components/layout';
interface RouteSignature {
    Args: {
        model: TasProgramInstanceModel;
    };
}
class TuitionAssistanceProgramInstancesShowIndexRouteComponent extends Component<RouteSignature> {
    @service
    sessionContext: SessionContextService;
    static{
        template_1a7d7926b4044035bb1fe130bba8f594(`
    {{pageTitle (t "tuition_assistance.program_details.default")}}
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb
        @route="authenticated.admin.tuition-assistance.program-instances.index"
        @label="Applications"
      />
      <b.crumb
        @route="authenticated.admin.tuition-assistance.program-instances.show"
        @label={{@model.programName}}
      />
    </TioPageBreadcrumbs>

    <HStack>
      <Section>
        <VStack @collapsed={{true}}>
          <TuitionAssistanceApplicationDetailsEmployeeInformation
            @instance={{@model}}
            @profileRoute="authenticated.admin.employees.show"
            @routeModel={{@model.employee.id}}
          />
          <TuitionAssistanceProgramDetailsProgramInfoCard
            @programInstance={{@model}}
            @showAttachments={{true}}
            class="w-full"
          />
          <TuitionAssistanceProgramDetailsApplicationInformation
            @application={{@model}}
            @isAdminView={{true}}
            @canRequestRepayment={{this.sessionContext.user.isAccountOwnerOrEmployeeAdmin}}
            @isReadonlyView={{false}}
            @requestingApp="employee"
            class="w-full"
          />
        </VStack>
      </Section>
      <Section>
        <VStack @collapsed={{true}}>
          <ApproverComments
            @commentable={{@model}}
            @commentableType="TasProgramInstance"
            @authoredByPerson={{this.sessionContext.user.person}}
            @commentTags={{array "approver"}}
          />
          <TuitionAssistanceApplicationDetailsApproverOverview
            @programTemplate={{@model.tasProgramTemplate}}
          />
        </VStack>
      </Section>
    </HStack>

    <TuitionAssistanceApplicationDetailsApprovalQueueStatus
      @application={{@model}}
      @consumingApp="employee"
      @isReadonlyView={{false}}
    />
    <Section>
      <:body>
        <TuitionAssistanceApplicationDetailsProgramStatusApproval
          @instance={{@model}}
          @routePrefix="authenticated.admin"
        />
      </:body>
    </Section>

    <TuitionAssistanceProgramDetailsAppDetailsAppHistory
      @application={{@model}}
      @viewType="admin"
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(TuitionAssistanceProgramInstancesShowIndexRouteComponent);

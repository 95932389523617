import { template as template_a9b26fa4165e4db0912eeff8392aa496 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { getSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { gt, not } from 'tio-ui/utilities';
import Component from '@glimmer/component';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type TasProgramInstance from 'tio-common/models/tas-program-instance';
import type DependentModel from 'tio-common/models/dependent';
import AddDependent from '../forms/add-dependents';
import FormRadio from '@frontile/forms-legacy/components/form-radio';
import UiPill from 'tio-common/components/ui/pill';
import FormInput from '@frontile/forms-legacy/components/form-input';
import DependentApplicationStepper from './dependent-application-stepper';
import { number, NumberSchema, ValidationError } from 'yup';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
interface TAProgramInstanceApplyDependentInformationSignature {
    Args: {
        programInstance: TasProgramInstance;
    };
}
const relationships = [
    {
        type: 'DependentRelationship.CHILD',
        name: 'Child'
    },
    {
        type: 'DependentRelationship.SPOUSE',
        name: 'Spouse'
    },
    {
        type: 'DependentRelationship.PARTNER',
        name: 'Partner'
    }
];
const ageFromBirthDate = (date: string)=>{
    return Math.floor((new Date().valueOf() - new Date(date).getTime().valueOf()) / 3.15576e10);
};
const getDependentRelationshipName = (dependent: DependentModel)=>{
    return relationships.find((relationship)=>relationship.type === dependent.relationship)!.name;
};
class TuitionAssistanceProgramInstanceApplyDependenInformationComponent extends Component<TAProgramInstanceApplyDependentInformationSignature> {
    @service
    store: typeof Store;
    @service
    router: RouterService;
    @tracked
    selectedDependent = '';
    @tracked
    showForm = false;
    @action
    toggleShowForm() {
        this.showForm = !this.showForm;
    }
    @action
    setDependent(value: unknown) {
        this.selectedDependent = value as string;
    }
    @action
    updateBirthdate(birthDate: string) {
        this.dependentApplying.birthDate = birthDate;
    }
    @action
    async updateInstanceAndAdvance() {
        if (this.dependentApplying.dirtyType === 'updated') {
            this.dependentApplying.save();
        }
        this.args.programInstance.dependent = this.dependentApplying;
        this.args.programInstance.setInitialDependentFields();
        await this.args.programInstance.save();
        this.router.transitionTo('authenticated.tuition-assistance.programs.instance.apply.program-information');
    }
    get dependentApplying(): DependentModel {
        return this.args.programInstance.employee.dependents.find((dependent)=>dependent.id === this.selectedDependent)!;
    }
    get ageVerification() {
        const ageLimit = getSingleValueForTasField('DEPENDENT_PROGRAM_AGE_LIMITS', this.args.programInstance.tasProgramTemplate.fields) as Record<string, number>;
        const dependentAge = ageFromBirthDate(this.dependentApplying.birthDate);
        const schemaMapping: Record<string, NumberSchema> = {
            PARTNER: number().max(ageLimit.PARTNER!, `The age limit for this program is ${ageLimit.PARTNER}`),
            SPOUSE: number().max(ageLimit.SPOUSE!, `The age limit for this program is ${ageLimit.SPOUSE}`),
            CHILD: number().max(ageLimit.CHILD!, `The age limit for this program is ${ageLimit.CHILD}`)
        };
        const relationship = this.dependentApplying.relationship.split('.')[1];
        const schema = schemaMapping[relationship as keyof typeof schemaMapping];
        if (!ageLimit[relationship as keyof typeof schemaMapping]) {
            return [];
        }
        try {
            schema!.validateSync(dependentAge);
            return [];
        } catch (err) {
            if (err instanceof ValidationError) {
                return [
                    err.message
                ];
            }
        }
        return [];
    }
    static{
        template_a9b26fa4165e4db0912eeff8392aa496(`
    <DependentApplicationStepper @showForm={{this.showForm}} @stage="Info" />
    <h3 class="font-semibold my-2 text-midnight">{{t
        "common.tuition_assistance.program_details.applicant_info"
      }}</h3>
    <h4>{{t "common.tuition_assistance.program_details.header"}}</h4>
    {{#if this.showForm}}
      <div class="mt-4 flex-col" data-test-dependent-info>
        <span class="flex my-6 w-96 justify-between items-baseline">
          {{this.dependentApplying.fullName}}
          <UiPill
            @label={{getDependentRelationshipName this.dependentApplying}}
            @bgColorClass="bg-gray-300"
            @textColorClass="text-gray-800"
            class="!flex !px-4 !py-2"
          />
          {{ageFromBirthDate this.dependentApplying.birthDate}}
          {{t "common.tuition_assistance.dependent_programs_information_years"}}
        </span>
        <form class="w-1/2">
          <div class="mt-2">
            <FormInput
              data-legacy-input
              @label={{t "common.tuition_assistance.add_dependent_modal.birth_date"}}
              @type="date"
              @value={{this.dependentApplying.birthDate}}
              @onChange={{this.updateBirthdate}}
              @errors={{this.ageVerification}}
              @showError={{true}}
              data-test-edit-dependent-birth-date
            />
          </div>
          <div class="flex my-6 gap-x-4">
            <Button
              @appearance="outlined"
              @intent="primary"
              {{on "click" this.toggleShowForm}}
              class="w-48"
            >
              {{t "common.back"}}
            </Button>
            <Button
              @intent="primary"
              {{on "click" this.updateInstanceAndAdvance}}
              class="w-48"
              disabled={{gt this.ageVerification.length 0}}
            >
              {{t "common.confirm"}}
            </Button>
            <Button
              @appearance="outlined"
              {{on "click" (transitionTo "authenticated.tuition-assistance.dependent-programs")}}
              class="w-48"
            >
              {{t "common.cancel"}}
            </Button>
          </div>
        </form>
      </div>
    {{else}}
      {{#if @programInstance.employee.dependents}}
        {{#each @programInstance.employee.dependents as |dependent|}}
          {{#if dependent.notDeleted}}
            <div class="my-6">
              <FormRadio
                data-legacy-input
                @value={{dependent.id}}
                class="items-center"
                name="dependent"
                @checked={{this.selectedDependent}}
                @onChange={{this.setDependent}}
              >
                <span class="flex w-96 justify-around items-start">
                  {{dependent.fullName}}
                  <UiPill
                    @label={{getDependentRelationshipName dependent}}
                    @bgColorClass="bg-gray-300"
                    @textColorClass="text-gray-800"
                    class="!flex !px-4 !py-2 -mt-2"
                  />
                  {{ageFromBirthDate dependent.birthDate}}
                  {{t "common.tuition_assistance.dependent_programs_information_years"}}
                </span>
              </FormRadio>
            </div>
          {{/if}}
        {{/each}}
      {{/if}}
      <AddDependent @employee={{@programInstance.employee}} />
      <div class="flex my-6">
        <Button
          @intent="primary"
          {{on "click" this.toggleShowForm}}
          class="w-48 uppercase"
          disabled={{not this.selectedDependent}}
          data-test-dependent-continue
        >
          {{t "common.continue"}}
        </Button>
        <Button
          @appearance="outlined"
          @intent="danger"
          {{on "click" (transitionTo "authenticated.tuition-assistance.dependent-programs")}}
          class="ml-8 w-48 uppercase"
        >
          {{t "common.cancel"}}
        </Button>
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default TuitionAssistanceProgramInstanceApplyDependenInformationComponent;

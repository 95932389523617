import { template as template_304f369d8dea4ebfb422d545191a2c6b } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import TioPageTabs from 'tio-common/components/tio/page/tabs';
import { t } from 'ember-intl';
import { Header, Section, VStack } from 'tio-ui/components/layout';
export default RouteTemplate(template_304f369d8dea4ebfb422d545191a2c6b(`
    <VStack>
      <Header>{{t "repayment_options.default"}}</Header>
      <TioPageTabs as |tabs|>
        <tabs.tab
          @label={{t "consolidation.default"}}
          @route="authenticated.questions-you-have.repayment-options.index"
        />
        <tabs.tab
          @label={{t "refinancing.default"}}
          @route="authenticated.questions-you-have.repayment-options.refinancing"
        />
      </TioPageTabs>

      <Section>
        <:body>
          {{outlet}}
        </:body>
      </Section>
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));

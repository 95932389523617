import { template as template_418b22e4f7d14b2b99c3d09cb38a9266 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { object, number, string } from 'yup';
import { getTranslationKeyForReportedSalaryForMatchPlan } from 'tio-common/utils/syf';
import TioForm from 'tio-common/components/tio/form';
import type MatchParticipantModel from 'tio-common/models/match-participant';
import type EmailAddressModel from 'tio-common/models/email-address';
import type { IntlService } from 'ember-intl';
import type Owner from '@ember/owner';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
interface SyfPostEnrollmentSignature {
    Args: {
        matchParticipant: MatchParticipantModel;
        emailAddress: EmailAddressModel;
        loading: boolean;
        onSubmit: () => void;
        onSkip: () => void;
    };
}
type SyfPostEnrollmentFormData = {
    reportedSalary: number;
    email: string;
};
class SyfEnrollmentConfirmationComponent extends Component<SyfPostEnrollmentSignature> {
    @service
    intl: IntlService;
    formSubmitAction: (() => void) | undefined = undefined;
    initialFormData: SyfPostEnrollmentFormData | Record<string, never> = {};
    constructor(owner: Owner, args: SyfPostEnrollmentSignature['Args']){
        super(owner, args);
        const { matchParticipant, emailAddress } = this.args;
        this.initialFormData = {
            reportedSalary: (matchParticipant.reportedSalary || 0) / 100,
            email: emailAddress.email || ''
        };
    }
    get reportedSalaryFieldLabel() {
        return this.intl.t(getTranslationKeyForReportedSalaryForMatchPlan(this.args.matchParticipant.matchPlan));
    }
    @action
    updateModelsWithFormValues(args: SyfPostEnrollmentSignature['Args'], formData: SyfPostEnrollmentFormData) {
        const { reportedSalary, email } = formData;
        const { matchParticipant, emailAddress } = args;
        matchParticipant.reportedSalary = reportedSalary * 100;
        emailAddress.email = email;
    }
    @action
    onSubmit(formData: SyfPostEnrollmentFormData) {
        this.updateModelsWithFormValues(this.args, formData);
        this.args.onSubmit();
    }
    @action
    onSkip() {
        this.args.onSkip();
    }
    formSchema = object().shape({
        reportedSalary: number().nullable(),
        email: string().email().nullable()
    });
    static{
        template_418b22e4f7d14b2b99c3d09cb38a9266(`
    {{! template-lint-disable no-bare-strings }}
    <h2 class="text-midnight text-large font-semibold mb-4">My Information</h2>
    <h3 class="font-semibold mb-2">We need a few more optional pieces of information to maximize
      your retirement benefits.</h3>

    <TioForm
      class=""
      @i18nPath="syf.plan_form"
      @data={{this.initialFormData}}
      {{! @glint-expect-error not sure}}
      @schema={{this.formSchema}}
      @onSubmit={{this.onSubmit}}
      as |form|
    >
      <div class="grid grid-cols-12 mt-8">
        <div class="col-span-4 col-start-5">
          <form.CurrencyField
            @containerClass="mb-8"
            @name="reportedSalary"
            @label={{this.reportedSalaryFieldLabel}}
            @hint="This information helps us estimate your benefit"
          />

          <form.InputField
            @containerClass="mb-8"
            @name="email"
            @type="email"
            @label="Personal Email"
            @hint="It is important for us to have a personal email for benefit communications"
          />

          <form.Errors class="my-6" />
        </div>
      </div>
      <div class="grid grid-cols-12 mt-8">
        <div class="col-span-4 col-start-5">
          <Button class="m-auto px-8 mb-4" type="submit" @intent="primary" @appearance="outlined">
            Go to Dashboard
          </Button>

          <Button
            @intent="primary"
            disabled={{@loading}}
            {{on "click" this.onSkip}}
            class="m-auto px-8 mb-4 capitalize"
          >
            Skip
          </Button>
        </div>
      </div>
    </TioForm>
    {{! template-lint-enable no-bare-strings }}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default SyfEnrollmentConfirmationComponent;

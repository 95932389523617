import { template as template_1d6e672fdd2c44e0a4683d2ab2eda908 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import TuitionAssistanceProgramCard from 'tio-common/components/tuition-assistance/program-card';
import type RouterService from '@ember/routing/router-service';
import type TasApplicationModel from 'tio-common/models/tas-application';
import UiPill from 'tio-common/components/ui/pill';
interface S {
    Args: {
        model: TasApplicationModel;
    };
}
class AdminTasDashboardRoute extends Component<S> {
    @service
    router: RouterService;
    get programTemplates() {
        return this.args.model.templates;
    }
    get requestedAmount() {
        return '$862,000.00';
    }
    get paidAmount() {
        return '$107,500.00';
    }
    @action
    goToReports() {
        this.router.transitionTo('authenticated.admin.reports');
    }
    @action
    goToApplications() {
        this.router.transitionTo('authenticated.admin.tuition-assistance.applications.index');
    }
    static{
        template_1d6e672fdd2c44e0a4683d2ab2eda908(`
    <VStack>
      <Header>{{t "dashboard"}}</Header>
      <Button {{on "click" this.goToApplications}} @intent="primary">
        {{t "tuition_assistance.dashboard.go_to_applications"}}
      </Button>
      <Section>
        <:header>
          {{t "tuition_assistance.programs"}}
        </:header>
        <:body>
          <div class="flex flex-wrap gap-4 pb-16">
            {{#each this.programTemplates as |template|}}
              <TuitionAssistanceProgramCard
                @buttonText="View Program Setup"
                @buttonRoute="authenticated.admin.tuition-assistance.program-templates.show"
                @buttonRouteModel={{template.id}}
                @title={{template.programName}}
                @titleClass="text-center pt-6"
              >
                {{#if template.isTrial}}
                  <div class="flex justify-center items-center">
                    <UiPill @label={{t "common.trial"}} @bgColorClass="bg-orange-700" />
                  </div>
                {{/if}}
              </TuitionAssistanceProgramCard>
            {{else}}
              {{t "tuition_assistance.no_programs_launched"}}
            {{/each}}
          </div>
        </:body>
      </Section>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(AdminTasDashboardRoute);

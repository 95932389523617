import { template as template_b9ddc770849b4b528f17bc2c0bcd3c64 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { and } from 'tio-ui/utilities';
import { Button } from 'tio-ui/components/buttons';
import { dropTask } from 'ember-concurrency';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import { htmlSafe } from '@ember/template';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { ValidationErrorKey } from 'tio-employee/services/employee';
import ChangeActiveAccountModal from 'tio-common/components/account/change-active-account-modal';
import Component from '@glimmer/component';
import DefinitionList from 'tio-common/components/ui/definition-list';
import RouteTemplate from 'ember-route-template';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import SlrError from 'tio-employee/components/slr/error';
import StatusText from 'tio-employee/components/slr/status-text';
import SupportEmailModal from 'tio-employee/components/slr/support-email-modal';
import Table from 'tio-employee/components/slr/table';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import type AccountModel from 'tio-common/models/account';
import type EmployeeService from 'tio-employee/services/employee';
import type IntlService from 'ember-intl/services/intl';
import type NotificationsService from '@frontile/notifications/services/notifications';
import type StoreService from 'tio-common/services/store';
import type UserModel from 'tio-common/models/user';
interface SlrDashboardSignature {
    Args: {
        model: UserModel;
    };
}
class SlrDashboardRouteComponent extends Component<SlrDashboardSignature> {
    @service
    employee: EmployeeService;
    @service
    store: StoreService;
    @service
    intl: IntlService;
    @service
    notifications: NotificationsService;
    @tracked
    isOpenAccountModal = false;
    @tracked
    isOpenSupportEmailModal = false;
    get user() {
        return this.args.model;
    }
    get pastContributionsPayments() {
        return this.employee.contributionPayments;
    }
    get nextContributionPayment() {
        return (this.employee.futureContributionPayments.find((payment)=>!payment.validation_errors.length) || this.employee.futureContributionPayments[0]);
    }
    get sortedContributions() {
        return this.pastContributionsPayments.sort((a, b)=>new Date(b.line_item_date).getTime() - new Date(a.line_item_date).getTime());
    }
    get lastPaymentAmount() {
        return (this.sortedContributions[0]?.amount || 0) / 100;
    }
    get lastPaymentDate() {
        return (this.sortedContributions[0]?.transaction_date || this.sortedContributions[0]?.line_item_period || '');
    }
    get lastPaymentStatus() {
        if (this.sortedContributions[0]?.transaction_date) {
            return this.intl.t('slr.payment_sent');
        } else if (this.sortedContributions[0]?.line_item_date) {
            return this.intl.t('slr.in_progress');
        } else return this.intl.t('slr.pending');
    }
    get nextPaymentAmount() {
        return (this.nextContributionPayment?.amount || 0) / 100;
    }
    get nextPaymentDate() {
        return this.nextContributionPayment?.date || '';
    }
    get nextPaymentStatus() {
        const status = this.nextContributionPayment?.state || '';
        if (status === 'PENDING_APPROVAL') {
            return this.intl.t('slr.pending');
        }
        return status.replace(/_/g, ' ').toLowerCase();
    }
    get totalPayments() {
        return this.employee.contributionSummary?.total / 100 || 0;
    }
    get paymentsCount() {
        return this.employee.contributionPayments?.length || 0;
    }
    get totalAnnualProjection() {
        return this.employee.annualPreviewData?.annual_projection || 0;
    }
    get yearlyTotal() {
        return this.employee.annualPreviewData?.paid_amount || 0;
    }
    get yearlyProjection() {
        return this.employee.annualPreviewData?.projected_amount || 0;
    }
    get isContributionsLoading() {
        return this.employee.isLoadingContributions;
    }
    get hasContributions() {
        return this.employee.hasContributions;
    }
    get validationError() {
        return this.nextContributionPayment?.validation_errors[0] || null;
    }
    get formattedValidationError() {
        if (this.validationError) {
            const { key, message } = this.validationError;
            switch(key){
                case ValidationErrorKey.ACCOUNTS_UNPAYABLE:
                    if (message.includes('address') || message.includes('payment method not set')) {
                        return {
                            content: this.intl.t('slr.error.no_address'),
                            emailModal: true
                        };
                    } else return {
                        content: this.intl.t('slr.error.accounts_unpayable'),
                        linkAccount: true
                    };
                case ValidationErrorKey.NO_LOAN_BALANCE:
                case ValidationErrorKey.INVALID_LOAN_TYPE:
                case ValidationErrorKey.STALE_ACCOUNT:
                    return {
                        content: this.intl.t('slr.error.accounts_unpayable'),
                        linkAccount: true
                    };
                case ValidationErrorKey.EXCEEDED_LIMIT:
                    return {
                        content: this.intl.t('slr.error.exceeded_limit')
                    };
                case ValidationErrorKey.NO_EMPLOYEE_TRANSACTIONS:
                    return {
                        content: this.intl.t('slr.error.no_transactions'),
                        emailModal: true
                    };
                case ValidationErrorKey.TERMS_NOT_ACCEPTED:
                    return {
                        content: this.intl.t('slr.error.terms_not_accepted')
                    };
                default:
                    return {
                        content: this.intl.t('slr.error.default')
                    };
            }
        } else return null;
    }
    @action
    onSupportEmailModalClick() {
        this.isOpenSupportEmailModal = true;
    }
    @action
    onCloseSupportEmailModal() {
        this.isOpenSupportEmailModal = false;
    }
    @action
    toggleChangeAccountModal() {
        this.isOpenAccountModal = !this.isOpenAccountModal;
    }
    updateAccountTask = dropTask(async (selectedAccount: AccountModel)=>{
        try {
            const accountPromises = this.user.activeAccounts.map((account)=>{
                account.publicOrdinalValue = account.id === selectedAccount?.id ? 0 : 1;
                if (account.hasDirtyAttributes) {
                    account.save();
                }
            });
            await Promise.all(accountPromises);
            this.notifications.add(this.intl.t('common.accounts.change_modal.success'), {
                appearance: 'success'
            });
        } catch (error) {
            console.error(error);
            this.notifications.add(this.intl.t('common.accounts.change_modal.error'), {
                appearance: 'error'
            });
        }
    });
    static{
        template_b9ddc770849b4b528f17bc2c0bcd3c64(`
    <VStack>
      <Header>
        <div class="flex items-center gap-x-4">
          {{t "slr.slr_page_header"}}
          <div class="flex items-center gap-x-1">
            <span class="{{if this.formattedValidationError 'text-red-500' 'text-green-500'}}">
              <pre>&bull;</pre>
            </span>

            <span class="text-black text-sm font-normal">
              {{t
                (if this.formattedValidationError "slr.issue_present" "slr.receiving_contributions")
              }}
            </span>
          </div>
        </div>
      </Header>
      <Section>
        <:header>{{t "slr.employer_contributions.title"}}</:header>
        <:body>
          <VStack>
            {{#if this.user.accountReceivingContributions}}
              <div class="flex items-center space-x-2">
                <h3 class="text-lg font-medium truncate">
                  {{t "slr.account_receiving_contributions"}}
                </h3>
                <span class="pr-4 border-r border-r-gray-500 truncate">
                  {{this.user.accountReceivingContributions.institutionName}}
                </span>
                <Button
                  @size="sm"
                  @appearance="minimal"
                  @intent="primary"
                  {{on "click" this.toggleChangeAccountModal}}
                >
                  {{t "slr.change"}}
                </Button>
              </div>

              {{#if this.formattedValidationError}}
                <SlrError as |options|>
                  <options.header>{{t "slr.error.needs_attention"}}</options.header>
                  <options.body>{{htmlSafe this.formattedValidationError.content}}</options.body>
                  {{#if this.formattedValidationError.emailModal}}
                    <options.footer>
                      <Button
                        {{on "click" this.onSupportEmailModalClick}}
                        @appearance="outlined"
                        class="!bg-gray-100"
                      >{{t "slr.attach_statement"}}</Button>
                    </options.footer>
                  {{else if this.formattedValidationError.linkAccount}}
                    <options.footer>
                      <Button
                        {{on "click" (transitionTo "authenticated.observability.upload")}}
                        @appearance="outlined"
                        class="!bg-gray-100"
                      >{{t "slr.link_account"}}</Button>
                    </options.footer>
                  {{/if}}
                </SlrError>
                {{#if this.validationError}}
                  <SupportEmailModal
                    @show={{this.isOpenSupportEmailModal}}
                    @onClose={{this.onCloseSupportEmailModal}}
                    @error={{this.validationError}}
                  />
                {{/if}}
              {{/if}}
            {{/if}}
            <div class="grid grid-cols-2 sm:grid-cols-4 sm:gap-4 md:gap-12">
              {{#if this.hasContributions}}
                <DefinitionList class="divide-none">
                  <:default as |options|>
                    <options.dt class="!text-black">
                      {{t "slr.employer_contributions.last"}}
                      <span class="bg-gray-100 py-0.5 px-3 ml-2 rounded">{{safeFormatDate
                          this.lastPaymentDate
                          month="short"
                          year="numeric"
                        }}</span>
                    </options.dt>
                    <options.dd class="*:text-2xl *:text-purple-900 decoration-purple-900">
                      {{safeFormatNumber this.lastPaymentAmount style="currency" currency="USD"}}
                    </options.dd>
                    <options.dd>
                      <StatusText
                        @status={{this.lastPaymentStatus}}
                        @lastPaymentDate={{this.lastPaymentDate}}
                      />
                    </options.dd>
                  </:default>
                </DefinitionList>
              {{/if}}
              {{#if (and this.nextPaymentDate this.nextPaymentAmount)}}
                <DefinitionList class="divide-none">
                  <:default as |options|>
                    <options.dt class="!text-black">
                      {{t "slr.employer_contributions.next"}}
                      <span class="bg-gray-100 py-0.5 px-3 ml-2 rounded">
                        {{safeFormatDate this.nextPaymentDate month="short" year="numeric"}}
                      </span>
                    </options.dt>
                    <options.dd class="*:text-2xl *:text-ocean-400 decoration-ocean-400">
                      {{safeFormatNumber this.nextPaymentAmount style="currency" currency="USD"}}
                    </options.dd>
                    <options.dd>
                      <StatusText @status={{this.nextPaymentStatus}} />
                    </options.dd>
                  </:default>
                </DefinitionList>
              {{/if}}
              {{#if this.paymentsCount}}
                <DefinitionList class="divide-none">
                  <:default as |options|>
                    <options.dt class="!text-black">
                      {{t "slr.total"}}
                    </options.dt>
                    <options.dd class="*:text-2xl *:text-midnight decoration-midnight">
                      {{safeFormatNumber this.totalPayments style="currency" currency="USD"}}
                    </options.dd>
                    <options.dd>
                      {{#let (safeFormatNumber this.paymentsCount) as |total|}}
                        {{t "slr.employer_contributions.total_contributions" total=total}}
                      {{/let}}
                    </options.dd>
                  </:default>
                </DefinitionList>
              {{/if}}
            </div>
            <Table @contributionPayments={{this.sortedContributions}} />
          </VStack>
        </:body>
      </Section>
    </VStack>

    <ChangeActiveAccountModal
      @onClose={{this.toggleChangeAccountModal}}
      @show={{this.isOpenAccountModal}}
      @accountOptions={{this.user.activeAccounts}}
      @accountReceivingContributions={{this.user.accountReceivingContributions}}
      @submitAction={{this.updateAccountTask}}
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(SlrDashboardRouteComponent);

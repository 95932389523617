import { template as template_fe6b23a55fbf45ab997000a7c5665114 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import pageTitle from 'ember-page-title/helpers/page-title';
import { t } from 'ember-intl';
export default RouteTemplate(template_fe6b23a55fbf45ab997000a7c5665114(`
    {{pageTitle (t "results")}}
    {{outlet}}
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));

import { template as template_75e818c5b26945839902631865725aef } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import LoadingSpinner from 'tio-employee/components/tas/loading-spinner';
export default RouteTemplate(template_75e818c5b26945839902631865725aef(`
    <div class="flex h-screen w-screen items-center justify-center">
      <LoadingSpinner />
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));

import { template as template_9f5f857b592b4a3d8f9b14b3630e0df3 } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { t } from 'ember-intl';
import Tile from '../tile';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const DashboardWidgetsCollegeCostCalculatorComponent: TOC = template_9f5f857b592b4a3d8f9b14b3630e0df3(`
  <LinkTo @route="authenticated.planning-for-college.college-cost-calculator">
    <Tile @headerText={{t "dashboard_tiles.college_financial_planner"}}>
      <:description>
        {{t "dashboard_tiles.prep_for_costs"}}
      </:description>
      <:image>
        {{svgJar
          "calculator-illustration"
          width="90%"
          height="100%"
          role="img"
          desc=(t "svg.calculator_illustration")
        }}
      </:image>
    </Tile>
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashboardWidgetsCollegeCostCalculatorComponent;

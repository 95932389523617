import { template as template_4b8b7d6905784216ae89208a8084c601 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { hash } from '@ember/helper';
interface S {
    Args: {
        canInviteFamily: unknown;
        nav: unknown;
    };
    Element: HTMLElement;
}
const AppSidebarNavGroupsEmployeeRepayingStudentDebtComponent: TOC<S> = template_4b8b7d6905784216ae89208a8084c601(`
  {{#let @nav as |nav|}}
    {{! @glint-expect-error: no idea}}
    <nav.group
      @icon="monetization_on"
      @label="Repaying Student Debt"
      @nestedRoute="repaying-student-debt"
      ...attributes
      as |group|
    >
      <group.item @nestedRoute="askjeni" @label="Student Loan Coaches" />
      <group.item @nestedRoute="loan-forgiveness" @label="Loan Forgiveness" />
      <group.item @nestedRoute="repayment-strategy-finder" @label="Strategy Finder" />
      <group.item @nestedRoute="repayment-options" @label="Consolidation & Refinancing" />
      {{#if @canInviteFamily}}
        <group.item
          @route="authenticated.profile"
          @routeQuery={{hash parentDashboard="repaying-student-debt"}}
          @label="Invite Family Member"
        />
      {{/if}}
      {{! @glint-expect-error: no idea}}
    </nav.group>
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppSidebarNavGroupsEmployeeRepayingStudentDebtComponent;

import { template as template_72a8361304ba4be2a3ec168c40559ece } from "@ember/template-compiler";
import { action } from '@ember/object';
import { fn } from '@ember/helper';
import { Modal } from 'tio-ui/components/modal';
import { Section } from 'tio-ui/components/layout';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AccountsLinkedLoansSummary from './linked-loans-summary';
import Component from '@glimmer/component';
import MaterialIcon from 'tio-common/components/material-icon';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
import type AccountModel from 'tio-common/models/account';
import type EmployeeService from 'tio-employee/services/employee';
import type PersonModel from 'tio-common/models/person';
import type RouterService from '@ember/routing/router';
import type SessionContextService from 'tio-employee/services/session-context';
import type Store from '@ember-data/store';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
export interface LinkedAccountsCardSignature {
    Args: {
        accounts: AccountModel[];
        person: PersonModel;
        programName: string;
        loanSummary: LoanSummary;
    };
    Element: HTMLElement;
}
export default class LinkedAccountsCard extends Component<LinkedAccountsCardSignature> {
    @service
    sessionContext: SessionContextService;
    @service
    store: typeof Store;
    @service
    employee: EmployeeService;
    @service
    router: RouterService;
    @tracked
    showViewDetailsDialog = false;
    get activeAccounts() {
        return this.args.accounts.filter((account: AccountModel)=>account.isActive);
    }
    get accountInstitutionDisplayNames() {
        return this.activeAccounts.slice().map((account: AccountModel)=>account.institutionName);
    }
    get hasActiveLinkedAccounts() {
        return !!this.activeAccounts.length;
    }
    @action
    submit() {
        this.router.transitionTo('authenticated.repaying-student-debt.askjeni');
    }
    static{
        template_72a8361304ba4be2a3ec168c40559ece(`
    <Section>
      <:header>{{t "idr.accounts.default"}}</:header>
      <:body>
        {{#if this.hasActiveLinkedAccounts}}
          <p>{{t "idr.accounts.linked_below"}}</p>
          <div class="grid grid-cols-1 justify-items-center min-w-full">
            <ul class="font-bold min-w-[75%] mx-4">
              {{#each this.accountInstitutionDisplayNames as |name|}}
                <li class="my-2 p-2 bg-ocean-50 flex justify-between">
                  <p class="flex-wrap">{{name}}</p>
                  <MaterialIcon @icon="check_circle" class="text-emerald-500" />
                </li>
              {{/each}}
            </ul>
            <div class="space-y-4">
              <Button
                @intent="primary"
                class="w-full"
                {{on "click" (transitionTo "authenticated.observability.upload")}}
              >
                {{t "idr.accounts.add_more_accounts"}}
              </Button>
              <Button
                @intent="primary"
                @appearance="outlined"
                class="w-full"
                {{on "click" (fn (mut this.showViewDetailsDialog) true)}}
              >
                {{t "idr.accounts.view_details"}}
              </Button>
            </div>
          </div>
        {{else}}
          <div class="grid grid-cols-1 justify-items-center min-w-full">
            <p>{{t "no_linked_accounts_alert.please_link_loans" program=@programName}}</p>
            <div>
              <Button
                @intent="primary"
                class="my-3 px-8"
                {{on "click" (transitionTo "authenticated.observability.upload")}}
              >
                {{t "pslf_dashboard.actions.add_accounts"}}
              </Button>
            </div>
          </div>
        {{/if}}
      </:body>
    </Section>

    <Modal
      @isOpen={{this.showViewDetailsDialog}}
      @onClose={{fn (mut this.showViewDetailsDialog) false}}
      class="min-h-64"
      @backdrop="faded"
      @size="full"
      as |m|
    >
      <m.Header>
        {{t "idr.accounts.accounts_linked"}}
      </m.Header>
      <m.Body>
        <AccountsLinkedLoansSummary
          @accounts={{@accounts}}
          @allowOverrideEdits={{true}}
          @loanSummary={{@loanSummary}}
          @person={{@person}}
        />
      </m.Body>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}

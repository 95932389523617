import { template as template_c06fa5d43f804141875dd8068ed9aed4 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface AppPageHeroSectionSignature {
    Blocks: {
        body: [];
        title: [];
    };
    Element: HTMLDivElement;
}
const AppPageHeroSectionComponent: TOC<AppPageHeroSectionSignature> = template_c06fa5d43f804141875dd8068ed9aed4(`
  <div class="bg-midnight text-white flex flex-col sm:flex-row gap-0 rounded-t-lg" ...attributes>
    <div class="p-10 text-2xl tracking-wide font-semibold text-center sm:text-left sm:w-1/3">
      {{yield to="title"}}
    </div>
    <div class="p-10 font-light text-center sm:text-left sm:w-2/3">
      {{yield to="body"}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppPageHeroSectionComponent;

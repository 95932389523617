import { template as template_82c96e47042e411e8eb970fc7ce41ac3 } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import type RecommendationModel from 'tio-common/models/recommendation';
import ForgivenessEstimatorResultInstructions from 'tio-employee/components/forgiveness-estimator/result-instructions';
import { Button } from 'tio-ui/components/buttons';
interface S {
    Args: {
        model: RecommendationModel;
    };
}
export default RouteTemplate<S>(template_82c96e47042e411e8eb970fc7ce41ac3(`
    {{pageTitle (t "recommendations_estimator.next_steps")}}
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb
        @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.index"
        @label="Dashboard"
      />
      <b.crumb
        @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.questionnaire.index"
        @label="Strategy Finder"
      />
      <b.crumb
        @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.questionnaire.results.index"
        @model={{@model}}
        @label="Results"
      />
      <b.crumb
        @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.questionnaire.results.next-steps"
        @label="Next Steps"
      />
    </TioPageBreadcrumbs>
    <ForgivenessEstimatorResultInstructions @recommendation={{@model}} />
    <div class="flex justify-center">
      <Button
        @intent="primary"
        {{on
          "click"
          (transitionTo "authenticated.repaying-student-debt.repayment-strategy-finder.dashboard")
        }}
        class="w-fit m-4"
      >
        {{t "recommendations_estimator.go_to_dashboard"}}
      </Button>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));

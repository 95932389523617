import { template as template_e5600b2658d64b1389874ac5d2748ce9 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { t } from 'ember-intl';
import { VStack, Header } from 'tio-ui/components/layout';
export default RouteTemplate(template_e5600b2658d64b1389874ac5d2748ce9(`
    <VStack>
      <Header>{{t "pslf.default_full"}}</Header>
      {{outlet}}
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));

import { template as template_b50ecab9ce464a2f8e387ea161c32dac } from "@ember/template-compiler";
import { concat } from '@ember/helper';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import TioAlert from 'tio-common/components/tio/alert';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import TuitionAssistanceProgramDetailsAppDetailsAppHistory from 'tio-common/components/tuition-assistance/program-details/app-details/app-history';
import TuitionAssistanceProgramDetailsApplications from 'tio-employee/components/tuition-assistance/program-details/applications';
import TuitionAssistanceProgramDetailsProgramInfoCard from 'tio-common/components/tuition-assistance/program-details/program-info-card';
import TuitionAssistanceProgramDetailsWithdrawComplete from 'tio-employee/components/tuition-assistance/program-details/withdraw-complete';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TuitionAssistanceService from 'tio-common/services/tuition-assistance';
export interface S {
    Args: {
        model: TasProgramInstanceModel;
    };
}
class TuitionAssistanceProgramsInstanceIndex extends Component<S> {
    @service
    tuitionAssistance: TuitionAssistanceService;
    get isActiveEligibility() {
        return this.tuitionAssistance.hasActiveEligibilityForProgramTemplate(this.args.model.tasProgramTemplate);
    }
    static{
        template_b50ecab9ce464a2f8e387ea161c32dac(`
    {{pageTitle
      (concat
        @model.tasProgramTemplate.programName " | " (t "tuition_assistance.program_details.default")
      )
    }}

    <VStack>
      <Header>{{t "tuition_assistance.default"}}</Header>
      <TioPageBreadcrumbs class="mb-4" as |b|>
        <b.crumb @route="authenticated.tuition-assistance.dashboard" @label={{t "dashboard"}} />
        <b.crumb
          @route="authenticated.tuition-assistance.programs.instance"
          @label={{@model.tasProgramTemplate.programName}}
        />
      </TioPageBreadcrumbs>

      <Section>
        <:body>
          {{!-- <ProgressTracker @model={{@model}} /> --}}
          {{#unless this.isActiveEligibility}}
            <TioAlert @type="warning" @allowDismiss={{false}} class="mb-4">
              <:header>
                <p class="font-bold">
                  {{t "tuition_assistance.inactive_participant_warnings.dashboard_title"}}
                </p>
              </:header>
              <:body>
                <p class="text-sm">
                  {{t "tuition_assistance.inactive_participant_warnings.dashboard_description"}}
                </p>
              </:body>
            </TioAlert>
          {{/unless}}
          {{!-- <TuitionAssistanceFormsChangeRequestAlert
          @transitionState={{array "WITHDRAWAL_NOT_APPROVED" "COMPLETION_NOT_APPROVED"}}
          @transitionLog={{@model.transitionLog}}
        /> --}}
          <TuitionAssistanceProgramDetailsProgramInfoCard
            @programInstance={{@model}}
            @showAttachments={{true}}
          />
          {{#unless @model.tasProgramTemplate.isTrackingOnlyProgram}}
            <TuitionAssistanceProgramDetailsApplications
              @programInstance={{@model}}
              @isHeldParticipant={{@model.employee.tasParticipant.isHeld}}
            />
          {{/unless}}
          <TuitionAssistanceProgramDetailsAppDetailsAppHistory
            @application={{@model}}
            @viewType="employee"
            class="my-8"
          />
          <TuitionAssistanceProgramDetailsWithdrawComplete
            @programInstance={{@model}}
            class="mt-12"
            @isHeldParticipant={{@model.employee.tasParticipant.isHeld}}
          />
        </:body>
      </Section>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(TuitionAssistanceProgramsInstanceIndex);

import { template as template_f0018a385f3d44539806fe7826e6cbe6 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import divide from 'ember-math-helpers/helpers/div';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import Table from 'tio-common/components/table/index';
import type { PastContributionPayment } from 'tio-employee/services/employee';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
export interface S {
    Args: {
        contributionPayments: PastContributionPayment[];
    };
}
const ContributionsAccountingPaymentsTable: TOC<S> = template_f0018a385f3d44539806fe7826e6cbe6(`
  <div class="flex flex-col space-y-2">
    <h3 class="text-lg font-medium">{{t "slr.table.header"}}</h3>
    {{#if @contributionPayments.length}}
      <Table @isLoading={{false}}>
        <:thead as |options|>
          <options.tr>
            <options.th>{{t "slr.table.benefit_cycle"}}</options.th>
            <options.th>{{t "slr.table.paid"}}</options.th>
            <options.th>{{t "slr.table.account"}}</options.th>
            <options.th>{{t "slr.total"}}</options.th>
          </options.tr>
        </:thead>
        <:tbody as |options|>
          {{#each @contributionPayments as |payment|}}
            <options.tr>
              <options.td>{{safeFormatDate
                  payment.line_item_period
                  month="short"
                  year="numeric"
                }}</options.td>
              <options.td>{{if
                  payment.transaction_date
                  (safeFormatDate
                    payment.transaction_date month="numeric" day="2-digit" year="numeric"
                  )
                  (t "slr.in_progress")
                }}</options.td>
              <options.td>{{payment.servicer}}</options.td>
              <options.td>{{safeFormatNumber
                  (divide payment.amount 100)
                  style="currency"
                  currency="USD"
                }}</options.td>
            </options.tr>
          {{/each}}
        </:tbody>
      </Table>
    {{else}}
      <p>{{t "slr.table.no_data"}}</p>
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ContributionsAccountingPaymentsTable;

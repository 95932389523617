import { template as template_b43eb24770b440d48d54274428ca9343 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import TioLoadingSpinner from 'tio-common/components/tio/loading-spinner';
export default RouteTemplate(template_b43eb24770b440d48d54274428ca9343(`
    <div class="flex h-screen w-screen items-center justify-center">
      <TioLoadingSpinner />
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));

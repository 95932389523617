import { template as template_fbb13f60fd1a4ec48c5bd4c9ef6f7c50 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import { on } from '@ember/modifier';
import { or } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import formatPhone from 'tio-common/helpers/format-phone';
import RouteTemplate from 'ember-route-template';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import type CompanySettingModel from 'tio-common/models/company-setting';
import type PartnerService from 'tio-employee/services/partner';
import type ZendeskService from 'tio-employee/services/zendesk';
export interface S {
    Args: {
        model: CompanySettingModel;
    };
}
class ContactUs extends Component<S> {
    @service
    zendesk: ZendeskService;
    @service
    partner: PartnerService;
    get support() {
        return this.partner.supportModule;
    }
    get isZendeskEnabled() {
        return this.zendesk.isEnabledForPartner;
    }
    get supportEmail() {
        if (!this.support || this.support?.tioEmail.isEnabled) {
            return 'support@tuition.io';
        } else if (this.support?.partnerEmail) {
            return this.support.partnerEmail;
        } else {
            return false;
        }
    }
    get tioPhoneEnabled() {
        return !this.support || this.support?.tioPhone.isEnabled;
    }
    get partnerPhoneNumber() {
        if (this.support?.partnerPhone) {
            return this.support.partnerPhone;
        } else {
            return false;
        }
    }
    @action
    onClickChat() {
        this.zendesk.openWebChatWindow();
    }
    static{
        template_fbb13f60fd1a4ec48c5bd4c9ef6f7c50(`
    <VStack>
      <Header>{{t "contact_us.default"}}</Header>
      <Section>
        <:body>
          <div class="grid place-content-center h-full">
            <div class="flex sm:flex-row flex-col sm:items-start">
              {{#if this.supportEmail}}
                <div class="flex-col grid justify-items-center m-5">
                  {{svgJar "email" width="45" height="45"}}
                  <div class="text-lg font-normal mt-2">{{t "contact_us.email_us"}}</div>
                  <a
                    href="mailto:{{this.supportEmail}}"
                    class="text-ocean-600 hover:text-ocean-800 text-sm"
                  >
                    {{this.supportEmail}}
                  </a>
                </div>
              {{/if}}
              {{#if (or this.partnerPhoneNumber this.tioPhoneEnabled)}}
                <div class="flex-col grid justify-items-center m-5">
                  {{svgJar "call" width="45" height="45"}}
                  <div class="text-lg font-normal mt-2">{{t "contact_us.call_us"}}</div>
                  <div class="grid justify-items-center text-gray-500 text-sm mt-1">
                    {{#if this.tioPhoneEnabled}}
                      <div class="text-center">{{t "contact_us.call_us_times" htmlSafe=true}}</div>
                      <p>
                        {{t "contact_us.tio_support_phone"}}
                      </p>
                    {{else if this.partnerPhoneNumber}}
                      <p>{{formatPhone this.partnerPhoneNumber}}</p>
                    {{/if}}
                  </div>
                </div>
              {{/if}}
              {{#if this.isZendeskEnabled}}
                <div class="flex-col grid justify-items-center m-5">
                  {{svgJar "headset_mic" width="45" height="45"}}
                  <div class="text-lg font-normal mt-2">
                    {{t "contact_us.chat_with_us"}}
                  </div>
                  <button
                    type="button"
                    class="text-ocean-600 hover:text-ocean-800 text-sm"
                    {{on "click" this.onClickChat}}
                  >{{t "contact_us.live_chat_now"}}</button>
                </div>
              {{/if}}
            </div>
          </div>
        </:body>
      </Section>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ContactUs);

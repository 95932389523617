import { template as template_f822da844cc841f2a8ad3dec7a08c0a7 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface PslfDashboardTodoNumberBadgeSignature {
    Args: {
        number: string;
    };
    Element: HTMLDivElement;
}
const PslfDashboardTodoNumberBadgeComponent: TOC<PslfDashboardTodoNumberBadgeSignature> = template_f822da844cc841f2a8ad3dec7a08c0a7(`
  <div
    class="rounded-full bg-ocean-600 text-white mr-2 w-6 h-6 flex items-center justify-center"
    ...attributes
  >
    {{@number}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PslfDashboardTodoNumberBadgeComponent;

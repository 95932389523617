import { service } from '@ember/service';
import Route from '@ember/routing/route';
import type AccountModel from 'tio-common/models/account';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type UserModel from 'tio-common/models/user';

type Resolved<P> = P extends Promise<infer T> ? T : P;
export type AccountActivityAccountsRouteModel = Resolved<
  ReturnType<AuthenticatedAccountActivityAccountsRoute['model']>
>;

export default class AuthenticatedAccountActivityAccountsRoute extends Route {
  @service declare store: StoreService;
  @service declare sessionContext: SessionContextService;

  async model() {
    const currentUser = this.sessionContext.user;

    const user: UserModel = await this.store.findRecord('user', currentUser?.id, {
      include: 'accounts.loans,accounts.financial-institution,accounts.postal-address,person',
      reload: true,
    });

    // HACK FOR BAD DATA in Vodka SPREADSHEET
    dateHack(user.accounts);

    return user;
  }
}

function dateHack(accounts: AccountModel[]) {
  accounts.forEach((account) => {
    let ids = account.loans.map(({ id }) => id);
    ids = ids.sort();
    ids.forEach((id) => {
      const loan = account.loans.find((e) => e.id == id);
      const { ['original-date']: originalDate, ['balance-date']: balanceDate } =
        loan?.observation || {};
      if (originalDate && balanceDate) {
        const wrongTime = '1970-01-01'; // example of bad data
        if (originalDate.startsWith(wrongTime)) {
          const og = new Date();
          og.setFullYear(og.getFullYear() - 2);
          // @ts-expect-error: this code need to be fixed. If above loan = {} then this will throw error
          loan.observation['original-date'] = og.toISOString();
        }
        if (balanceDate.startsWith(wrongTime)) {
          const bal = new Date();
          bal.setDate(bal.getDate() - 2);
          // @ts-expect-error: this code need to be fixed. If above loan = {} then this will throw error
          loan.observation['balance-date'] = bal.toISOString();
        }
      }
    });
  });
}

import { template as template_46159d06ae11460295e08fbd7a9b1cbc } from "@ember/template-compiler";
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import TuitionAssistanceFormsPreApprovalProgramInformation from 'tio-employee/components/tuition-assistance/forms/pre-approval/program-information';
import DependentApplicationStepper from 'tio-employee/components/tuition-assistance/program-details/dependent-application-stepper';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
import type { CustomFieldSignature } from 'tio-common/types/tuition-assistance';
import type { TASProgramInstanceModelFieldsSignature } from 'tio-common/models/tas-program-instance';
import type { TasProgramsInstanceApplyProgramInformationRouteModel } from 'tio-employee/routes/authenticated/tuition-assistance/programs/instance/apply/program-information';
import { Section } from 'tio-ui/components/layout';
interface TAProgramInstanceApplyEmployeeInformationRouteSignature {
    Args: {
        model: TasProgramsInstanceApplyProgramInformationRouteModel;
    };
}
class TuitionAssistanceProgramInstanceApplyProgramInformationRouteComponent extends Component<TAProgramInstanceApplyEmployeeInformationRouteSignature> {
    @service
    store: typeof Store;
    @service
    router: RouterService;
    @action
    cancel() {
        this.router.transitionTo('authenticated.tuition-assistance.dashboard');
    }
    @action
    async saveForLater(fieldsCopy: TASProgramInstanceModelFieldsSignature, customFieldsCopy: CustomFieldSignature) {
        await this.saveFields.perform(fieldsCopy, customFieldsCopy);
        if (this.args.model.programInstance.isDependentProgram) {
            this.router.transitionTo('authenticated.tuition-assistance.dependent-programs');
        } else {
            this.router.transitionTo('authenticated.tuition-assistance.dashboard');
        }
    }
    @action
    async saveAndNext(fieldsCopy: TASProgramInstanceModelFieldsSignature, customFieldsCopy: CustomFieldSignature, isFormValid: boolean) {
        if (!isFormValid) {
            return;
        }
        await this.saveFields.perform(fieldsCopy, customFieldsCopy);
        this.router.transitionTo('authenticated.tuition-assistance.programs.instance.apply.review');
    }
    saveFields = dropTask(async (fieldsCopy, customFieldsCopy)=>{
        this.args.model.programInstance.fields = fieldsCopy;
        this.args.model.programInstance.customFields = customFieldsCopy;
        try {
            await this.args.model.programInstance.save();
        } catch (e) {
            console.error(e);
        }
    });
    static{
        template_46159d06ae11460295e08fbd7a9b1cbc(`
    {{#if @model.programInstance.tasProgramTemplate.isDependentProgram}}
      <DependentApplicationStepper @stage="Program" />
    {{/if}}
    <Section>
      <:header>{{t "tuition_assistance.preapproval_app.program_info"}}</:header>
      <:body>
        <h4>{{t "tuition_assistance.preapproval_app.subtitle_step2"}}</h4>

        <TuitionAssistanceFormsPreApprovalProgramInformation
          @programInstance={{@model.programInstance}}
          @selectedSchool={{@model.selectedSchool}}
          {{! @glint-expect-error }}
          @saveAndNext={{this.saveAndNext}}
          {{! @glint-expect-error }}
          @saveForLater={{this.saveForLater}}
          @cancel={{this.cancel}}
          @showActionButtons={{true}}
          @saveTaskIsRunning={{this.saveFields.isRunning}}
        />
      </:body>
    </Section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(TuitionAssistanceProgramInstanceApplyProgramInformationRouteComponent);

import { template as template_b745046a027e4a0390fc0c60f390ddc6 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { TransitionLogSignature } from 'tio-common/types/tuition-assistance';
import { t } from 'ember-intl';
import TioAlert from 'tio-common/components/tio/alert';
import type Owner from '@ember/owner';
export interface ChangeRequestAlertSignature {
    Args: {
        transitionLog: TransitionLogSignature[];
        transitionState: string | string[];
    };
    Element: HTMLDivElement;
}
interface statesMapSignature {
    [key: string]: string;
}
export default class ChangeRequestAlert extends Component<ChangeRequestAlertSignature> {
    @tracked
    changeRequested: string;
    constructor(owner: Owner, args: ChangeRequestAlertSignature['Args']){
        super(owner, args);
        this.changeRequested = this.transitionLogChangeRequest as string;
    }
    get transitionLogChangeRequest() {
        const transitionLog = (this.args && this.args.transitionLog) || [];
        const secondToLastLog = transitionLog[transitionLog.length - 2];
        if (!secondToLastLog || !this.transitionStateString.includes(secondToLastLog.to)) {
            return '';
        }
        return secondToLastLog.reason?.note;
    }
    get transitionStateString() {
        const statesMap: statesMapSignature = {
            COMPLETION_NOT_APPROVED: 'TAS.ProgramInstanceState.COMPLETION_NOT_APPROVED',
            COURSES_NOT_APPROVED: 'TAS.ApplicationState.COURSES_NOT_APPROVED',
            EVIDENCE_NOT_APPROVED: 'TAS.ApplicationState.EVIDENCE_NOT_APPROVED',
            PROGRAM_NOT_APPROVED: 'TAS.ProgramInstanceState.PROGRAM_NOT_APPROVED',
            WITHDRAWAL_NOT_APPROVED: 'TAS.ProgramInstanceState.WITHDRAWAL_NOT_APPROVED'
        };
        if (Array.isArray(this.args.transitionState)) {
            return this.args.transitionState.map((state)=>statesMap[state]).filter(Boolean);
        } else {
            return statesMap[this.args.transitionState] || '';
        }
    }
    static{
        template_b745046a027e4a0390fc0c60f390ddc6(`
    {{#if this.changeRequested}}
      <TioAlert @type="warning" @allowDismiss={{true}} class="max-w-8xl m-4">
        <:header>
          <p class="font-semibold">{{t "tuition_assistance.your_approver_has_requested"}}</p>
        </:header>
        <:body>
          <p class="text-sm">{{this.changeRequested}}</p>
        </:body>
      </TioAlert>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}

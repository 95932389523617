import { template as template_a489a391051f4e02a6b95f3abe9b78a8 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
export default RouteTemplate(template_a489a391051f4e02a6b95f3abe9b78a8(`
    {{! template-lint-disable no-bare-strings }}
    <h1>Start page for testing</h1>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));

import { template as template_a4ed9c331d8f4f2397e5d818a17c07f0 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { and, not } from 'tio-ui/utilities';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import awsUrl from 'tio-employee/helpers/aws-url';
import Component from '@glimmer/component';
import ShouldIRefinance from '../should-i-refinance';
import TioAlert from 'tio-common/components/tio/alert';
import { Divider } from 'tio-ui/components/utilities';
import type { IntlService } from 'ember-intl';
import type PartnerService from 'tio-employee/services/partner';
interface S {
    Element: HTMLElement;
}
export default class RepaymentOptionsRefinancingComponent extends Component<S> {
    @service
    partner: PartnerService;
    @service
    intl: IntlService;
    @tracked
    recommendRefinance = false;
    @action
    recommendation(status: boolean) {
        this.recommendRefinance = status;
    }
    static{
        template_a4ed9c331d8f4f2397e5d818a17c07f0(`
    {{#if this.partner.shouldShowCustomRefinancingWarning}}
      <TioAlert @type="info" @allowDismiss={{true}} class="mx-10 my-6">
        <:body>
          <p>{{t "wellness.fed_reserve_acknowledgement_1" htmlSafe=true}}</p>
        </:body>
      </TioAlert>

      <TioAlert @type="warning" @allowDismiss={{true}} class="mx-10 my-6">
        <:body>
          <p>{{t "wellness.fed_reserve_acknowledgement_2" htmlSafe=true}}</p>
        </:body>
      </TioAlert>
    {{/if}}

    <section class="tio-copy" ...attributes>
      <h3 class="text-2xl my-6">
        {{t "wellness.what_is_refinancing"}}
      </h3>
      <div class="{{if this.partner.shouldShowRefinanceApply 'grid grid-cols-1 sm:grid-cols-3'}}">
        <div class="col-span-2">
          <p class="my-4">{{t "wellness.refinancing_description"}}</p>
          <ul>
            <li>
              <a href="#refinancing-should-i" class="my-3 block">
                {{t "wellness.refinancing_should_i"}}
              </a>
            </li>
            <li>
              <a href="#refinancing-benefits" class="my-3 block">{{t "benefits"}}</a>
            </li>
            <li>
              <a href="#refinancing-considerations" class="my-3 block">
                {{t "wellness.refinancing_other_considerations"}}
              </a>
            </li>
            {{#if this.partner.shouldShowRefinanceApply}}
              <li>
                <a href="#refinancing-apply" class="my-3 block">
                  {{t "wellness.refinancing_how_to_apply"}}
                </a>
              </li>
            {{/if}}
          </ul>
        </div>
        {{#if this.partner.isLendkey}}
          <div class="border-l border-l-4 border-midnight p-8 ml-8">
            <a href={{this.partner.lendkeyHref}} target="_blank" rel="noopener noreferrer">
              <h3 class="text-xl">{{t "wellness.refinancing_check_out_marketplace"}}</h3>

              <img
                src="/assets/images/lend-key.svg"
                alt={{t "repayment_options.lendkey"}}
                width="131"
                height="30"
                class="pt-4"
              />
            </a>
          </div>
        {{/if}}
      </div>
    </section>

    <Divider class="my-9" />

    <section id="refinancing-benefits" class="tio-copy">
      <h3 class="text-2xl my-6">{{t "wellness.refinancing_benefits"}}</h3>
      <p>{{t "wellness.refinancing_benefits_description"}}</p>

      {{#if this.partner.isLendkey}}
        <div class="grid grid-cols-3 gap-8">
          <p class="my-4">
            {{t
              "wellness.refinancing_download_guide"
              link=(awsUrl "staticData/Ultimate_Guide_to_Refi.pdf")
              htmlSafe=true
            }}
          </p>

          <img
            src="/assets/images/lend-key-guide.png"
            width="122"
            height="118"
            alt={{t "repayment_options.lendkey_guide"}}
            class="col-span-2"
          />
        </div>
      {{/if}}
    </section>

    <Divider class="my-9" />

    <section id="refinancing-should-i" class="tio-copy">
      <h3 class="text-2xl my-6">{{t "wellness.refinancing_should_i"}}</h3>
      <ShouldIRefinance @recommendation={{this.recommendation}} />
      <div class="m-4">
        {{#if (and this.recommendRefinance (not this.partner.isLendkey))}}
          <b>{{t "wellness.refinancing_do_recommend"}}</b>
          <p>
            {{t "wellness.refinancing_do_recommend_description_without_lendkey"}}
          </p>
        {{else if this.recommendRefinance}}
          <b>{{t "wellness.refinancing_do_recommend"}}</b>
          <p>
            {{t
              "wellness.refinancing_do_recommend_description"
              link="http://lendkey.com"
              htmlSafe=true
            }}
          </p>

        {{else}}
          <b>{{t "wellness.refinancing_dont_recommend"}}</b>
          <p>
            {{t "wellness.refinancing_dont_recommend_description"}}
            <LinkTo @route="authenticated.repaying-student-debt.repayment-strategy-finder">
              {{t "wellness.repayment_options_need_more_help_description_link"}}
            </LinkTo>
          </p>
        {{/if}}
      </div>
    </section>

    <Divider class="my-9" />

    <section id="refinancing-considerations">
      <h3 class="text-2xl my-6">{{t "wellness.refinancing_else_to_know"}}</h3>
      <p class="my-4">{{t "wellness.refinancing_else_to_know_description"}}</p>
      <p class="my-4">{{t "wellness.refinancing_else_to_know_description_payment_flexibility"}}</p>
    </section>

    {{! How to apply section }}
    {{#if this.partner.isLendkey}}
      <Divider class="my-9" />

      <section id="refinancing-apply" class="tio-copy">
        <h3 class="text-2xl my-6">{{t "wellness.refinancing_how_to_apply"}}</h3>
        <div class="grid grid-cols-1 sm:grid-cols-3">
          <div class="col-span-2">
            <p class="my-4">{{t "wellness.refinancing_applying_lendkey"}}</p>
            <p class="my-4">{{t "wellness.refinancing_not_for_profit"}}</p>
            <p class="my-4">{{t "wellness.refinancing_lendkey_offer_best"}}</p>
            <p class="my-4">{{t "wellness.refinancing_click_and_type_your_name"}}</p>
          </div>
          <div class="border-l border-l-4 border-midnight p-8 ml-8 mb-8">
            <a href={{this.partner.lendkeyHref}} target="_blank" rel="noopener noreferrer">
              <h3 class="text-xl">{{t "wellness.refinancing_check_out_marketplace"}}</h3>
              <img
                src="/assets/images/lend-key.svg"
                alt={{t "repayment_options.lendkey"}}
                width="131"
                height="30"
                class="pt-4"
              />
            </a>
          </div>
        </div>
      </section>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}

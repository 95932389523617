import { template as template_d5e506734efa481887c4e6ad5a21db06 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import type FeaturesService from 'tio-common/services/features';
import type PartnerService from 'tio-employee/services/partner';
import type SessionContextService from 'tio-employee/services/session-context';
import type { NavGroup } from 'tio-common/components/ui/sidebar/nav';
import AdminReports from './nav-groups/admin/reports';
import AdminEmployees from './nav-groups/admin/employees';
import AdminTuitionAssistance from './nav-groups/admin/tuition-assistance';
import AdminEligibilityFiles from './nav-groups/admin/eligibility-files';
import AdminPslf from './nav-groups/admin/pslf';
export interface AppSidebarAdminNavSignature {
    Args: {
        nav: {
            group: NavGroup;
        };
    };
}
export default class AppSidebarAdminNavComponent extends Component<AppSidebarAdminNavSignature> {
    @service
    features: FeaturesService;
    @service
    partner: PartnerService;
    @service
    sessionContext: SessionContextService;
    get isTaOnly() {
        return this.partner.isTaOnly;
    }
    get isEnabledTuitionAssistance() {
        return this.partner.hasNewTuitionAssistance;
    }
    static{
        template_d5e506734efa481887c4e6ad5a21db06(`
    {{#let @nav as |nav|}}
      {{#if this.isTaOnly}}
        <AdminReports @nav={{nav}} />
        <AdminEmployees @nav={{nav}} />
        <AdminTuitionAssistance @nav={{nav}} />
        <AdminEligibilityFiles @nav={{nav}} />
      {{else}}
        {{#if this.sessionContext.isAdminView}}
          <AdminReports @nav={{nav}} />
        {{/if}}
        <AdminEmployees @nav={{nav}} />
        <AdminEligibilityFiles @nav={{nav}} />
        {{#if this.partner.isPslfEnabled}}
          <AdminPslf @nav={{nav}} />
        {{/if}}

        {{#if this.isEnabledTuitionAssistance}}
          <AdminTuitionAssistance @nav={{nav}} />
        {{/if}}
      {{/if}}
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}

import { template as template_93dd3e83ca3945669049b8b09388b14b } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import pageTitle from 'ember-page-title/helpers/page-title';
import { t } from 'ember-intl';
import type TasApplicationModel from 'tio-common/models/tas-application';
import UiPill from 'tio-common/components/ui/pill';
import { Header, VStack } from 'tio-ui/components/layout';
interface S {
    Args: {
        model: TasApplicationModel;
    };
}
export default RouteTemplate<S>(template_93dd3e83ca3945669049b8b09388b14b(`
    {{pageTitle (t "tuition_assistance.application_details.default")}}
    <VStack>
      <Header>
        {{t "tuition_assistance.application_details.default"}}
        {{#if @model.migrationApplicationId}}
          <UiPill @label={{t "tuition_assistance.migrated"}} @bgColorClass="bg-emerald-700" />
        {{/if}}
        {{#if @model.isTrial}}
          <UiPill @label={{t "common.trial"}} @bgColorClass="bg-orange-700" />
        {{/if}}
      </Header>
      {{outlet}}
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));

import { template as template_36458b3c8bff4fd1b8e4fa9bb8e69cd9 } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import type SessionContext from 'tio-employee/services/session-context';
import TasPayablesReport from 'tio-common/components/reporting/tas/payables';
import { VStack } from 'tio-ui/components/layout';
class ReportingTasPayablesRouteComponent extends Component {
    @service
    sessionContext: SessionContext;
    static{
        template_36458b3c8bff4fd1b8e4fa9bb8e69cd9(`
    <VStack>
      <TasPayablesReport
        @returnRoute="authenticated.admin.reporting.index"
        @sessionContext={{this.sessionContext}}
      />
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ReportingTasPayablesRouteComponent);

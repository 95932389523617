import { template as template_6ebd2091c21241fc978079d5ea9bf6c0 } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { t } from 'ember-intl';
import Tile from '../tile';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const DashboardWidgetsInviteFamilyComponent: TOC = template_6ebd2091c21241fc978079d5ea9bf6c0(`
  <LinkTo @route="authenticated.profile" @query={{hash parentDashboard="dashboard"}}>
    <Tile @headerText={{t "dashboard_tiles.invite_family_members"}}>
      <:description>
        {{t "dashboard_tiles.share_with_family"}}
      </:description>
      <:image>
        {{svgJar "family" role="img" width="60%" height="100%" desc=(t "svg.family")}}
      </:image>
    </Tile>
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashboardWidgetsInviteFamilyComponent;

import { template as template_6788330a55754619bb087f200031a877 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
interface Signature {
    Args: {
        // TODO: type this like the tuition-assistance module
        // - James 2024-04-09
        nav: unknown;
    };
}
const AppSidebarNavGroupsAdminEligibilityFilesComponent: TOC<Signature> = template_6788330a55754619bb087f200031a877(`
  {{#let @nav as |nav|}}
    {{! @glint-expect-error: no idea}}
    <nav.item
      @nestedRoute="admin.eligibility-files"
      @icon="upload_file"
      @label={{t "sidebar.eligibility_files"}}
    />
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppSidebarNavGroupsAdminEligibilityFilesComponent;

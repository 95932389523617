import { template as template_d304cc8ba2a8474f8e77fef15313505f } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { t } from 'ember-intl';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import type MatchPlanModel from 'tio-common/models/match-plan';
import { on } from '@ember/modifier';
import { Button } from 'tio-ui/components/buttons';
import { concat } from '@ember/helper';
import urlFor from 'ember-router-helpers/helpers/url-for';
export interface SyfAlternateFlowEnrollmentSignature {
    Element: HTMLElement;
    Args: {
        matchPlan: MatchPlanModel;
        onConfirmation: () => void;
    };
}
const SyfAlternateFlowEnrollmentComponent: TOC<SyfAlternateFlowEnrollmentSignature> = template_d304cc8ba2a8474f8e77fef15313505f(`
  {{! template-lint-disable no-bare-strings }}
  <h2 class="text-midnight text-large font-semibold mb-2">
    {{t "syf.enrollment.review.benefit_enrollment_review"}}
  </h2>
  <div class="flex justify-center my-4">
    {{svgJar "alternate-flow" height="266px" role="img" desc=(t "svg.alternate_flow")}}
  </div>
  <section class="my-10">
    <div class="grid md:grid-cols-6 mx-8 my-4">
      <h3 class="font-semibold mb-2 md:col-start-2 md:col-span-3">
        {{t "syf.enrollment.review.benefit_details"}}
      </h3>
      <ul class="md:col-start-2 md:col-span-4 list-disc ml-8 text-sm md:text-base">
        <li class="my-1.5 ml-2">
          {{@matchPlan.matchStatement}}
        </li>
        <li class="my-1.5 ml-2">
          {{@matchPlan.matchFrequencyDescription}}
        </li>
      </ul>
    </div>
    <div class="grid md:grid-cols-6 mx-8 my-4">
      <h3 class="font-semibold mb-2 md:col-start-2 md:col-span-3">
        {{t "syf.enrollment.review.whats_next"}}
      </h3>
      <div class="md:col-start-2 md:col-span-4 text-sm md:text-base">
        {{t "syf.enrollment.review.whats_next_body"}}
      </div>
    </div>
    <div class="flex flex-col-reverse md:flex-row md:justify-center items-center gap-4 mt-16">
      <Button
        @intent="primary"
        @appearance="outlined"
        {{on
          "click"
          (transitionTo
            (concat (urlFor "authenticated.observability.upload") "?source=syf_enrollment")
          )
        }}
        class="mx-8 w-48"
      >
        {{t "common.back"}}
      </Button>
      <Button @intent="primary" {{on "click" @onConfirmation}} class="mx-8 w-48 uppercase">
        {{t "syf.enrollment.enroll"}}
      </Button>
    </div>
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SyfAlternateFlowEnrollmentComponent;

import { template as template_87b1e4abbf0d4edca5d339640b9bb84f } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import LoadingSpinner from 'tio-employee/components/tas/loading-spinner';
export default RouteTemplate(template_87b1e4abbf0d4edca5d339640b9bb84f(`
    <div class="flex h-screen w-screen items-center justify-center">
      <LoadingSpinner />
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));

import { template as template_3a2b4fff92ce425ab8f72b3755f9ed9b } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import embedAwsQuicksight from 'tio-common/modifiers/embed-aws-quicksight';
interface S {
    Args: {
        model: {
            url: string;
        };
    };
}
export default RouteTemplate<S>(template_3a2b4fff92ce425ab8f72b3755f9ed9b(`
    <div class="w-full">
      <div {{embedAwsQuicksight @model.url}}></div>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));

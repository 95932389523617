import { template as template_b8ef91c47543401e873bd0f6edb72e5f } from "@ember/template-compiler";
import { HStack, Section, VStack } from 'tio-ui/components/layout';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import ApplicationHistory from 'tio-common/components/tas/application-history';
import Component from '@glimmer/component';
import NavTabs from 'tio-ui/components/nav-tabs';
import RouteTemplate from 'ember-route-template';
import type { Step } from 'tio-common/components/tio/progress-tracker';
import type ProgressBuilder from 'tio-common/services/progress-builder';
import type TASProgramInstanceModel from 'tio-common/models/tas-program-instance';
import VerticalProgressTracker from 'tio-common/components/tio/vertical-progress-tracker';
interface RouteSignature {
    Args: {
        model: TASProgramInstanceModel;
    };
}
class AuthenticatedTasProgramsHistory extends Component<RouteSignature> {
    @service
    progressBuilder: ProgressBuilder;
    get programProgress() {
        return this.progressBuilder.programProgress(this.args.model) as Step[];
    }
    static{
        template_b8ef91c47543401e873bd0f6edb72e5f(`
    <VStack>
      <NavTabs class="w-full" as |navtabs|>
        <navtabs.item @route="authenticated.tas.programs.show">
          {{t "tas.program.program"}}
        </navtabs.item>
        <navtabs.item @route="authenticated.tas.programs.details">
          {{t "tas.program.details"}}
        </navtabs.item>
        <navtabs.item @route="authenticated.tas.programs.history">
          {{t "tas.program.history"}}
        </navtabs.item>
      </NavTabs>
      <HStack>
        <Section class="overflow-hidden">
          <:header>{{t "tuition_assistance.program_instances.history.header"}}</:header>
          <:body><ApplicationHistory @history={{@model.history}} @type="program" /></:body>
        </Section>
        <Section class="md:!w-1/3">
          <:header>{{t "common.progress_tracker.program_progress"}}</:header>
          <:body>
            {{! TODO: using lists to show numbers causes flow issues. The ml-4 class will force
                the numbers into the box, but this isn't ideal as mobile view will have issues.
                Looks like the component is just a stack anyway so might be target for update. }}
            <VerticalProgressTracker class="ml-4" @steps={{this.programProgress}} />
          </:body>
        </Section>
      </HStack>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(AuthenticatedTasProgramsHistory);
